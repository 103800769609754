<div class="sidebar-panel">

  <div
    id="scroll-area"
    
    [perfectScrollbar]
    class="navigation-hold"
   
    fxLayout="column"
  >
    <div class="sidebar-hold"
    >
      <!-- App Logo -->
      <div class="branding">
        <img src="assets/images/sortlogo/The Hotel Mate Logo_Symbol Dark (1).png" alt="" class="app-logo" />
        <span class="app-logo-text"> The Hotel Mate</span>

        <span
          style="margin: auto"
          *ngIf="layoutConf?.sidebarStyle !== 'compact'"
        ></span>
        <div
          class="sidebar-compact-switch"
          [ngClass]="{ active: layoutConf?.sidebarCompactToggle }"
          (click)="toggleCollapse()"
          *ngIf="layoutConf?.sidebarStyle !== 'compact'"
        >
          <span></span>
        </div>
      </div>

      <!-- Sidebar user -->
      <div class="app-user">
        <h4 class="text-white">{{ property.name }}</h4>
        <div class="app-user-photo">
          <img
            [src]="property.logoUrl"
            height="100"
            width="100"
            class="mat-elevation-z1"
            alt=""
          />
        </div>
        <span class="app-user-name mb-8">
          <mat-icon class="icon-xs text-muted"  routerLink="sessions/lockscreen">lock</mat-icon>
          <div
            *ngIf="
              this.applicationUser != null && this.applicationUser != undefined
            "
            
          >
            {{ this.applicationUser.firstName }} &nbsp;
            {{ this.applicationUser.lastName }}
          </div>
        </span>
        <!-- Small buttons -->
        <div class="app-user-controls"
        >
          <!-- <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            matTooltip="Profile"
            routerLink="/profile/overview"
          >
            <mat-icon>account_box</mat-icon>
          </button> -->
          <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            matTooltip="Refresh"
            (click)="refresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
          <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            [matMenuTriggerFor]="appUserMenu"
          >
            <mat-icon>settings</mat-icon>
          </button>
          <button
            class="text-muted"
            mat-icon-button
            mat-xs-button
            matTooltip="Sign Out"
            (click)="jwtAuthService.logout()"
          >
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
              <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/profile/settings">
              <mat-icon>settings</mat-icon>
              <span>Account Settings</span>
            </button>
            <!-- <button mat-menu-item routerLink="/calendar">
                        <mat-icon>date_range</mat-icon>
                        <span>Calendar</span>
                    </button> -->
            <button mat-menu-item (click)="jwtAuthService.logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <!-- Navigation -->
      <app-sidenav
        *ngIf="isViewLoaded"
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
    </div>
  </div>
</div>
