import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs/observable/fromPromise";
import { sha256 } from "js-sha256";
import * as qz from "qz-tray";

@Injectable({
  providedIn: "root",
})
export class PrinterService {
  constructor() {
    qz.api.setSha256Type((data: any) => sha256(data));
    qz.api.setPromiseType((resolver: any) => new Promise(resolver));
  }

  connect(PrintObject) {
    if (!qz.websocket.isActive()) {
      // qz.websocket.connect()
      //   .then(qz.printers.getDefault)
      //   .then(printer => console.log("The default printer is: " + printer))
      //   .catch((err) => console.log("Try1 : " + err));
      qz.websocket
        .connect()
        .then(qz.printers.getDefault)
        // .then(() => {
        //   return qz.printers.find();
        // })
        .then((printers) => {
          console.log(printers);
          let config = qz.configs.create(printers);
          return qz.print(config, [
            {
              type: "pixel",
              format: "html",
              flavor: "plain",
              data: PrintObject,
            },
          ]);
        })
        .then(() => {
          console.log(" Ingrogreddd ");
          // return qz.websocket.disconnect();
        })
        .then(() => {
          console.log(" Ingrogreddd2 ");
          this.disconnect();
        })
        .catch((err) => {
          console.error(err);
          console.log(" Ingrogreddd3 ");
        });
      console.log(" END ");
    } else {
      console.log("Already connection");
    }
  }

  disconnect() {
    if (qz.websocket.isActive()) {
      qz.websocket
        .disconnect()
        .then((res) => console.log("Con : " + res))
        .catch((err) => console.log("Try : " + err));
    } else {
      console.log("no connection");
    }
  }
}
