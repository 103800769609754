import { OrderLineDto } from "./orderLineDto";
import { OrderProduct } from "./product";
import { ShipToAddress } from "./address";
import { TaxDetails } from "app/views/bookone-onboarding/tax-details/model/TaxDetails";
import { KOT } from "./KOT";

export class Order {
  advanceAmount: number;
  bookOneOrderId: string;
  bookOneReviewId: number;
  bookingId: number;
  totalCount: number;
  businessReservationNumber: string;
  businessServiceId: number;
  createdBy:string;
  businessServiceTypeId: number;
  counterName: string;
  counterNumber: string;
  couponCode: string;
  currency: string;
  customerId: number;
  customerName: string;
  deliveryChargeAmount: number;
  deliveryCode: string;
  deliveryMethod: string;
  discountAmount: number;
  discountPercentage: number;
  email: string;
  expenseId: number;
  externalOrderId: string;
  externalSite: string;
  firstName: string;
  id: number;
  invoiceId: number;
  invoiceNumber: string;
  lastName: string;
  locationName: string;
  mobile: string;
  modeOfPayment: string;
  netAmount: number;
  operatorName: string;
  orderLineDtoList: OrderLineDto[];
  orderPaymentStatus: string;
  orderStatus: string;
  orderedDate: string;
  orderedTime: string;
  paymentId: number;
  phone: string;
  productDtoList: OrderProduct[];
  propertyId: number;
  propertyLogoUrl: string;
  propertyName: string;
  refundAmount: number;
  requiredDate: string;
  requiredTime: string;
  resourceName: string;
  roomNo: string;
  serviceChargeAmount: number;
  serviceId: number;
  shipToAddress: ShipToAddress;
  shipperId: number;
  specialNotes: string;
  subTotalAmount: number;
  taxAmount: number;
  taxDetails: TaxDetails[];
  totalOrderAmount: number;
  serviceChargeName: string;
  serviceChargePercentage: number;
  noOfPerson: number;
  message: string;
  kotDtoList: KOT[];
  orderSlot: string;
  orderCompleteTime: string;
  planName: string;
  paidAmount: number;
  outstandingAmount: number;
  invoicePrintCount: number;

  afterTaxAmount: number;
  beforeTaxAmount: number;
  beforeTaxAmountAfterDiscount: number;

  constructor() { }
}
