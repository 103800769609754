import { Logger } from "../../../services/logger.service";
import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationEnd, NavigationExtras } from "@angular/router";
import { NotificationService } from "app/shared/services/notification.service";
import { TokenStorage } from "app/token.storage";
import { Notifications } from "./../../models/notification.model";
import { CheckUserType } from "app/checkUserType";
import { PrinterService } from "app/services/Printer/Printer/printer-service.service";
import { OrderService } from "app/views/order/services/order.service";
import { Order } from "app/views/order/model/order";
import { KOT } from "app/views/order/model/KOT";
import { Property } from "app/views/bookone/property/property";
import { BusinessServiceDtoList } from "app/views/order/model/businessUser/businessServiceDtoList";
import { HttpErrorResponse } from "@angular/common/http";
import { DateService } from "app/services/bookoneDate/date-service.service";
import { PropertyService } from "app/views/bookone-admin/services/Property/propertyservice.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  roleArray: any = [];
  role: any = [];

  checkUserType: CheckUserType;

  notifications: Notifications[];
  notificationNew: Notifications[];
  notificationOrder: Notifications[];

  timeIntevalSeconds = 300;

  timeIntevalSecondsOrder = 300;

  cancel: string = "warn";
  confirm: string = "success";

  message: string;
  order: Order;

  kotList: KOT[] = [];
  property: Property;

  businessService: BusinessServiceDtoList;

  constructor(
    private router: Router,
    private printerService: PrinterService,
    private propertyService: PropertyService,
    private notificationService: NotificationService,
    public dateService: DateService,
    private orderService: OrderService,
    private token: TokenStorage
  ) {
    this.property = new Property();
    this.order = new Order();
    this.roleArray = this.token.getRole();
    this.checkUserType = new CheckUserType();

    this.role = [];
    JSON.parse(this.token.getRole()).forEach((item) => {
      this.role.push(item);
    });

    const filters = {
      roles: (roles) =>
        roles.find((x) => this.roleArray.includes(x.toUpperCase())),
    };
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });

    this.propertyDetails(this.token.getProperty().id);

    if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      if (this.token.getProperty().propertyStatus === "COMPLETED") {
        this.callNotification();
      }
    }
    this.callOrderNotification();
  }

  propertyDetails(propertyId: number) {
    this.propertyService.getPropertyDetailsByPropertyId(propertyId).subscribe(
      (data) => {
        this.property = data;
      },
      (error) => {
        if (error instanceof HttpErrorResponse) {
        }
      }
    );
  }

  callOrderNotification() {
    this.getOrderNotification();
    let refreshIntervalId = setInterval(() => {
      this.getOrderNotification();
      if (
        this.token.getProperty() === null ||
        this.token.getProperty() === undefined
      ) {
        clearInterval(refreshIntervalId);
      }
    }, this.timeIntevalSecondsOrder * 200);
  }

  getOrderNotification() {
    this.notificationOrder = [];
    this.notificationService
      .getNotificationForOrder(+this.token.getPropertyId())
      .subscribe((resp) => {
        this.notificationOrder = resp.body;

        if (
          this.notificationOrder != null &&
          this.notificationOrder != undefined &&
          this.notificationOrder.length > 0
        ) {
          this.getOrderByOrderId(this.notificationOrder[0].referenceId);
          this.notificationOrder[0].notificationStatus = "Read";
          this.notificationService
            .saveNotification(this.notificationOrder[0])
            .subscribe((resp) => {
              this.notificationOrder = [];
            });
        }
      });
  }

  callNotification() {
    this.getNotification();
    let refreshIntervalId = setInterval(() => {
      this.getNotification();
      if (
        this.token.getProperty() === null ||
        this.token.getProperty() === undefined
      ) {
        clearInterval(refreshIntervalId);
      }
    }, this.timeIntevalSeconds * 1000);
  }

  onRoutePage(row) {
    row.notificationStatus = "Read";
    this.saveNotification(row);
    this.onRoutePageRedrect(row);
  }

  onRoutePageRedrect(row) {
    row.notificationStatus = "Read";
    this.saveNotification(row);

    if (row.notificationType.toLowerCase() === "order") {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          data: JSON.stringify(row.referenceId),
        },
      };
      this.router.navigate(["/order/order-details"], navigationExtras);
    } else if (row.notificationType.toLowerCase() === "invoice") {
      this.router.navigate(["/invoice/invoice-list"]);
    } else if (row.notificationType.toLowerCase() === "booking") {
      this.router.navigate(["/bookone/manage-booking/list"]);
    } else if (row.notificationType.toLowerCase() === "payment") {
      this.router.navigate(["/bookone/manage-payment"]);
    } else if (row.notificationType.toLowerCase() === "reservation") {
      this.router.navigate([
        "/master-service/reservation-details/" + row.referenceId,
      ]);
    } else if (row.notificationType.toLowerCase() === "availability") {
      this.router.navigate(["/bookone/manage-rates-availability"]);
    } else if (row.notificationType.toLowerCase() === "call waiter") {
      this.router.navigate(["/bookone/dashboard"]);
    }
  }
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }
  getNotification() {
    this.notificationNew = [];
    this.notificationService
      .getNotificationForProperty(+this.token.getPropertyId())
      .subscribe((resp) => {
        this.notifications = resp.body;

        this.notificationNew = resp.body;
        this.notificationNew.reverse();

        this.notificationNew = this.notificationNew.filter((item) => {
          const searchResult =
            item.notificationStatus != null &&
            item.notificationStatus.toString().toLowerCase().indexOf("new") >
              -1;
          return searchResult;
        });
        if (
          Number(this.token.getNotificationValue()) <
          this.notificationNew.length
        ) {
          this.playAudio();
        }
        this.token.saveNotificationValue(String(this.notificationNew.length));
      });
  }

  getOrderByOrderId(orderId: number) {
    this.order = new Order();
    this.kotList = [];
    this.orderService.getOrderByOrderId(orderId).subscribe(
      (data) => {
        this.order = data.body;

        if (
          this.order.orderStatus != null &&
          this.order.orderStatus != "Completed" &&
          this.order.orderStatus != "Cancelled" &&
          this.order.orderStatus != "Submitted"
        ) {
          if (
            this.order.kotDtoList != null &&
            this.order.kotDtoList.length > 0
          ) {
            this.kotList = this.order.kotDtoList;
          }

          this.businessService = new BusinessServiceDtoList();
          this.businessService = this.property.businessServiceDtoList.find(
            (data) => data.id === this.order.businessServiceId
          );

          this.printKOT();
        }
      },
      (error) => {}
    );
  }

  printKOT() {
    let content2 = `
    <html>
    <head>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    </head>
    <body style="">
      <table style="width: 100%; font-size: 10px; font-family: Arial !important">
        <tr>
          <td>
            <div class="profile-sidebar mb-1 pb-0" style="text-align: center">
              <div class="profile-title text-center mb-1" style="font-size: 12px">
                ${
                  this.businessService != null &&
                  this.businessService != undefined &&
                  this.businessService.name != null &&
                  this.businessService.name != undefined
                    ? `
                <div class="main-title">
                  ${
                    this.order.deliveryMethod === "Room Order"
                      ? `
                  <span>Room</span>`
                      : ``
                  }
                  ${
                    this.order.deliveryMethod != "Room Order"
                      ? `<span>${this.businessService.name}</span>`
                      : ``
                  }
                  Order Token
                </div>
                ${
                  this.businessService == null ||
                  this.businessService == undefined
                    ? `
                <div class="main-title">
                  ${
                    this.order.deliveryMethod === "Room Order"
                      ? `
                  <span>Room</span>`
                      : ``
                  }
                  ${
                    this.order.deliveryMethod != "Room Order"
                      ? `
                  <span>Kitchen</span>`
                      : ``
                  }
                  Order Token
                </div>
                `
                    : ``
                }

                <div class="subtitle mb-05">
                  ${this.order.bookOneOrderId}
                </div>
                ${
                  this.order.deliveryMethod === "Room Order"
                    ? `
                <div class="subtitle mb-05">
                  <strong>Room No: </strong> ${this.order.roomNo}
                </div>
                `
                    : ``
                }
              </div>
              ${
                this.order.locationName != null &&
                this.order.locationName != undefined
                  ? `
              <div>
                <div style="width: 100%; font-size: 12px">
                  <div><strong>Location:</strong> ${this.order.locationName}</div>
                  <div><strong>Resource:</strong> ${this.order.resourceName}</div>
                </div>
              </div>
              `
                  : ``
              }
              <div style="width: 100%; font-size: 12px">
                <strong>Order Date: </strong>
                ${
                  this.order.orderedDate != null ||
                  this.order.orderedDate != undefined
                    ? `
                <span>${this.dateService.convertMillisecondsToDateFormat(
                  this.order.orderedDate
                )}</span>
                `
                    : ``
                }
              </div>
              <div style="width: 100%; font-size: 12px">
                <strong>Order Time: </strong> ${this.order.orderedTime}
              </div>
              ${
                this.order.specialNotes != null &&
                this.order.specialNotes != undefined
                  ? `
              <div style="width: 100%; font-size: 12px">
                <strong>Notes: </strong> ${ this.order.specialNotes }
              </div>
              `
                  : ``
              }
              ${
                this.order.operatorName != null
                  ? `
              <div style="width: 100%; font-size: 12px">
                <strong>Operator: </strong> ${this.order.operatorName}
              </div>
              `
                  : ``
              }
            </div>
          </td>
        </tr>
      </table>
 ${this.kotList
   .map(
     (kot, index) => `
      <div  style="margin-left:-10px;margin-right:10px">
      ${
        this.getKotOrderLine(kot) != null &&
        this.getKotOrderLine(kot) != undefined &&
        this.getKotOrderLine(kot).length > 0
          ? `
      <h2
        style=" margin-top: 10px;font-size: 12px;">
        ${kot.kotNo}
        <span style="font-size: 12px"
          >Priority : ${kot.priority}</span
        >
      </h2>`
          : ``
      }
      ${
        this.getKotOrderLine(kot) != null &&
        this.getKotOrderLine(kot) != undefined &&
        this.getKotOrderLine(kot).length > 0
          ? `
      <table
        style="
        font-family: Arial !important;
        text-align: center;
        width: 100%;
        font-size: 10px;
        border-collapse: collapse;
        "
      >
        <thead>
          <tr>
            <th style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">#</th>
            <th style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">NAME</th>
            <th style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">QTY</th>
            <th style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">STATUS</th>
            <th style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">NOTES</th>
          </tr>
        </thead>

        <tbody>
        ${
          kot.orderLines === null ||
          kot.orderLines === undefined ||
          kot.orderLines.length === 0
            ? `
          <tr
            fxLayout="row">
            VOID KOT
          </tr>`
            : ``
        }
          ${kot.orderLines
            .map(
              (row, i) => `
          <tr>
          ${
            this.checkStatus(row) === "Available"
              ? `
            <ng-container style="font-size:8px;">
              <td style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">(${i + 1})</td>
              <td style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">${row.name.toUpperCase()}</td>
              <td style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">${row.unitsInOrder}</td>
              <td style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">${row.status}</td>
              <td style=" border: 1px solid #C1C1C1;
             text-align: center;
             padding:3px;width:20%">
              ${
                row.notes != undefined && row.notes != null
                  ? `
                <strong>${row.notes}</strong>`
                  : ``
              }
              </td>
            </ng-container>`
              : ``
          }
          </tr>`
            )
            .join("")}
        </tbody>
      </table>`
          : ``
      }
    </div> `
   )
   .join("")}

      <div style="
        font-family: Arial !important;
        text-align: center;
        font-size: 12px;
        margin-top: 10px;
      ">
        Powered by BookOne.
      </div>
    </body>
    </html>
  `
                    : ``
                }`;

    console.log("KOT Is Available : " + this.isKOTAvailable());

    if (this.isKOTAvailable() === true) {
      this.printerService.connect(content2);
    }
  }

  isKOTAvailable() {
    let isAvailable = false;
    if (
      this.kotList != null &&
      this.kotList != undefined &&
      this.kotList.length > 0
    ) {
      for (let i = 0; i < this.kotList.length; i++) {
        if (
          this.getKotOrderLine(this.kotList[i]) !== null &&
          this.getKotOrderLine(this.kotList[i]) !== undefined &&
          this.getKotOrderLine(this.kotList[i]).length > 0
        ) {
          isAvailable = true;
        }
      }
    }

    return isAvailable;
  }

  getKotOrderLine(kot) {
    if (
      kot != null &&
      kot != undefined &&
      kot.orderLines != null &&
      kot.orderLines != undefined &&
      kot.orderLines.length > 0
    ) {
      return kot.orderLines.filter(
        (data) => this.checkStatus(data) === "Available"
      );
    } else {
      return null;
    }
  }

  checkStatus(product) {
    if (
      product.status === null ||
      product.status === undefined ||
      product.status === ""
    ) {
      return (product.status = "Available");
    }

    return product.status;
  }

  getValue(value) {
    if (value != null && value != undefined) {
      return value;
    } else {
      return "";
    }
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../../../../assets/audio/notification.mp3";
    audio.load();
    audio.play();
  }
  saveNotification(notifications) {
    this.notificationService
      .saveNotification(notifications)
      .subscribe((resp) => {
        this.notifications = resp.body;
        this.getNotification();
      });
  }

  capitalize(word) {
    if (word != null && word != undefined && word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return "";
    }
  }
}
