import { Component, OnInit, Input, OnDestroy, Renderer2 } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { OrgNavigationService } from '../../../shared/services/OrgNavigationService';
import { PropNavigationService } from '../../../shared/services/PropNavigationService';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { AuthService } from '../../../services/auth.service';
import { TokenStorage } from './../../../token.storage';
import { CheckUserType } from '../../../checkUserType';
import { Property } from './../../../views/bookone/property/property';
import { BusinessNavigationService } from 'app/shared/services/BusinessNavigationService';
import { NotificationService } from 'app/shared/services/notification.service';
import { Notifications } from 'app/shared/models/notification.model';
import { Logger } from 'app/services/logger.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})

export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  role: any = [];
  timeIntevalSeconds = 120;
  notifications: Notifications[];

  checkUserType: CheckUserType;
  menuItemSub: Subscription;
  property: Property;
  egretThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }
    // , {
    //   name: 'Spanish',
    //   code: 'es',
    // }
  ]
  @Input() notificPanel;
  constructor(
    private businessNavigationService: BusinessNavigationService,
    private layout: LayoutService,
    private navService: NavigationService,
    private propNavService: PropNavigationService,
    private orgNavService: OrgNavigationService,
    public themeService: ThemeService,
    private notificationService: NotificationService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public authService: AuthService,
    private token: TokenStorage
  ) {
    this.checkUserType = new CheckUserType();
    this.property = new Property();


    this.role = [];
    JSON.parse(this.token.getRole()).forEach(item => {
      this.role.push(item);
    });
  }


  ngOnInit() {

    this.property = this.token.getProperty();
    //Logger.log('header Top : '+JSON.stringify( this.property.logoUrl))

    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;

    if (this.checkUserType.isSuperAdmin(this.role[0]) == true) {
      this.superAdmin();
    }
    else if (this.checkUserType.isOrganization(this.role[0]) == true) {
      this.orgAdmin();
    }
    else if (this.checkUserType.isOrganizationCustomerSupport(this.role[0]) == true) {
      Logger.log('customer support haeder top')
      this.orgCustomerSupportAdmin();
    }
    else if (this.checkUserType.isHotelAdmin(this.role[0]) == true) {
      if (this.token.getProperty().businessType.toLocaleLowerCase() !== 'accommodation' && this.token.getProperty().propertyStatus === 'COMPLETED') {
        this.BusinessService();
      } else {
        this.hotelAdmin();
      }
    }

  }

  orgCustomerSupportAdmin() {
    this.menuItemSub = this.orgNavService.menuItemsCustomerSupport$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }

  BusinessService() {
    this.menuItemSub = this.businessNavigationService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }

  hotelAdmin() {
    this.menuItemSub = this.propNavService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }
  orgAdmin() {
    this.menuItemSub = this.orgNavService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }
  superAdmin() {
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 4
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe()
  }
  setLang() {
    this.translate.use(this.currentLang)
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name })
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
}
