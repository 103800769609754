// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from "config";

export const environment = {
  production: false,
  appId: "hotelmate",
  // masterProductUrl:"",
  // homeAddress: "http://uat.thehotelmate.com",
  // apibookUrl:'http://testconnect.bookone.io/hotelmate',
  // apiUrl: "http://api.uat.thehotelmate.com",
  // apiUrlInventory: "http://api.uat.bookone.io/ims",
  // apiUrlEms: "http://api.uat.bookone.io/ems",
  // apiUrllms: "http://api.uat.bookone.io/lms",
  // apiUrlPromotion: "http://api.uat.bookone.io/promotion",
  // cmApiUrl: "https://channel-integration.uat.bookone.io",
  // addressUrl: "http://api.uat.bookone.io/address",
  // scheduleApiUrl: "https://testapi.bookonelocal.co.nz/bookone-scheduler",
  // apiEglobe: "http://eglobe.connect.uat.bookone.io",
  // apiGoogle: "https://n-a.com",
  googleHotelUrl: "https://n-a.com",

  homeAddress: "https://bookonelocal.in",
  masterProductUrl:"https://masterdata.bookonepms.com",
  apiUrl: "https://api.thehotelmate.com",
  apiUrlInventory: "https://api.bookonelocal.in/api-ims",
  apiUrlEms: "https://api.bookonelocal.in/api-ems",
  apiUrllms: "https://api.bookonelocal.in/api-lms",
  cmApiUrl: "https://api.bookonelocal.in/channel-integration",
  addressUrl: "https://api.bookonelocal.in/api-address",
  apiUrlPromotion: "https://api.bookonelocal.in/promotion-api",
  scheduleApiUrl: "https://api.bookonelocal.in/api-scheduler",
  apiEglobe: "https://api.bookonelocal.in/api-eglobe",
  apiGoogle: "https://testapi.bookonelocal.co.nz/api-google-hotels",

  country: "in",
  showLog: true,
  parentOrganisationId: 1,
};
