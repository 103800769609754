import { Component, OnInit, AfterViewInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { RoutePartsService } from "./shared/services/route-parts.service";

import { filter } from "rxjs/operators";
import { UILibIconService } from "./shared/services/ui-lib-icon.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = "The Hotel Mate";
  pageTitle = "";

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private iconService: UILibIconService
  ) {
    this.iconService.init();
  }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {}

  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        const routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) {
          return this.title.setTitle(this.appTitle);
        }
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
export const APP_ID = "The Hotel Mate";

export const API_URL = environment.apiUrl;
// export const  API_BookUrl= environment.apibookUrl;
export const API_URL_MASTER = environment.masterProductUrl;
export const API_URL_EMS = environment.apiUrlEms;
export const API_URL_LMS = environment.apiUrllms;
export const API_URL_PROMOTION = environment.apiUrlPromotion;
export const CM_API_URL = environment.cmApiUrl;
export const API_URL_ADDRESS = environment.addressUrl;
export const API_URL_SCHEDULE = environment.scheduleApiUrl;
export const API_URL_GOOGLE = environment.apiGoogle;

export const CUSTOMER_APP_URL = "https://bookone-customer-app.web.app/#/";

// export const API_URL = 'https://api.bookonelocal.in/api-bookone';
// export const API_URL_PROMOTION = 'https://api.bookonelocal.in/promotion-api';
// export const CM_API_URL = 'https://api.bookonelocal.in/channel-integration/';
// export const API_URL_ADDRESS = 'https://api.bookonelocal.in/promotion-api';

export const SMS_NUMBER = "+19569032629";

export const DEFAULT_LOGO = "./../assets/images/property-default.svg";
export const DEFAULT_IMAGE = "./../assets/images/default-image.svg";
export const BUSINESS_DEFAULT_LOGO = "./../assets/images/business_logo.svg";
export const EMAIL_TEMPLATE_DEFAULT_LOGO =
  "./../assets/images/email_template.png";

export const DEFAULT_ROOM_IMAGE = "./../assets/images/roomimage.png";
export const DEFAULT_ROOM_IMAGE_PROFILE =
  "./../assets/images/room-main-image.png";

export const SUPER_ADMIN = "SUPER_ADMIN";
export const USER = "USER";
export const ORG_ADMIN = "ORG_ADMIN";
export const ORG_FINANCE = "ORG_FINANCE";
export const ORG_SALES = "ORG_SALES";
export const ORG_CUST_SUPPORT = "ORG_CUST_SUPPORT";
export const CUST_SUPPORT = "CUST_SUPPORT";
export const HOTEL_ADMIN = "PROP_ADMIN";
export const PROP_FO_EXECUTIVE = "PROP_FO_EXECUTIVE";
export const PROP_SERVICE_EXECUTIVE = "PROP_SERVICE_EXECUTIVE";
export const HOTEL_MANAGER = "PROP_MANAGER";
export const HOTEL_FRONTDESK = "PROP_FRONTDESK";
export const HOTEL_SERVICE = "PROP_SERVICE";
export const HOTEL_FINANCE = "PROP_FINANCE";
export const HOUSE_KEEPING = "PROP_HOUSEKEEPING";
export const HOTEL_MARKETING = "PROP_MARKETING";
export const SMS_SUBSCRIPTION = "SMS Subscription";

export const AUDIT_NEW_BOOKING = "NEW_BOOKING";
export const AUDIT_ROOM_ALLOCATION = "ROOM_ALLOCATION";
export const AUDIT_ROOM_RELEASE = "ROOM_RELEASE";
export const AUDIT_CHECK_IN = "CHECK_IN";
export const AUDIT_ROOM_CATEGORY_CHANGE = "ROOM_CATEGORY_CHANGE";
export const AUDIT_BOOKING_UPDATE = "BOOKING_UPDATE";
export const AUDIT_DATE_CHANGE = "DATE_CHANGE";
export const AUDIT_BOOKING_CHECKOUT = "BOOKING_CHECKOUT";
export const AUDIT_GUEST_DETAILS_UPDATE = "GUEST_DETAILS_UPDATE";
export const AUDIT_BOOKING_CANCELLED = "BOOKING_CANCELLED";
export const AUDIT_ROOM_SHIFT = "ROOM_SHIFT";

export const AUDIT_ORDER_CREATE = "ORDER_CREATE";
export const AUDIT_ORDER_UPDATE = "ORDER_UPDATE";
export const AUDIT_ORDER_CANCEL = "ORDER_CANCEL";
export const AUDIT_ORDER_DELETE = "ORDER_DELETE";

export const EXTRAADULT = "Extra-Adult";
export const EXTRACHILD = "Extra-Child";

export const CONT_EMAIL = "support@thehotelmate.com";
// export const CONT_MOBILE = "+919040785705/+919004129369";


export const BUSINESS_TYPE_SOFTWARE_CONSULTING = "Software Consulting Services";
export const SMS_HOST = "SMS Host";
export const PAYMENT_LINK = "Payment Link";
export const FLOWID = "63772845417b4840546e5c76";
export const HOME_ADDRESS = environment.homeAddress;
export const STATER_IMAGE_NO = 1;
export const PERMIUM_AND_ESSENTIAL_IMAGE_NO = 40;
export const INVOICE_BUSINESS_OP_PROPERTY_ID = 269;
export const BUSINESS_LEADS_ORGANIZATION_ID = environment.parentOrganisationId;
export const SESSION_APP_ID = "BOOKONE_WEB_APP";

// Validations
export const PhoneNumberEXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const PasswordValidationEXP =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$/;
export const EmailValidationEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NumberValidationEXP = /^\d+$/;

export const EMAIL_Expression: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
