import { Address } from "./../../order/model/businessUser/address";
import { TaxDetails } from "app/views/bookone-onboarding/tax-details/model/TaxDetails";
import { Customer } from "./../customer";
import { Service } from "./../service/service";
import { BedDetails } from "./../availability/availability.component";
import { Property } from "../../bookone/property/property";
import { RoomDetails } from "../booking/RoomDetails";
import { Payment } from "../payment/payment";
import { Expense } from "../manage-expense/expense/expense";
import { PropertyServiceDTO } from "app/views/master-service/model/PropertyServices";

export class Booking {
  id: number;
  groupBooking: boolean;
  customerId: number;
  propertyReservationNumber: string;
  referenceNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  CalculatorDays:string;
  fromDate: string;
  fromDateCal: Date;
  toDate: string;
  roomType: string;
  roomPrice: string;
  dayTrip: boolean;
  airportShuttlePrice: string;
  businessEmail: string;
  businessName: string;
  notes: string;
  externalBookingId: string;
  externalSite: string;
  commissionAmount: string;
  gstAmount: string;
  paymentSurcharge: string;
  netAmount: number;
  airportService: string;
  accomodationType: string;
  roomId: number;
  propertyId: number;
  available: boolean;
  modeOfPayment: string;
  cardNumber: number;
  expMonth: number;
  expYear: number;
  cvv: number;
  currency: string;
  token: string;
  bookingAmount: number;
  payableAmount: number;
  roomName: string;
  services: Service[];
  totalServiceAmount: number;
  totalExpenseAmount: number;
  totalPaymentAmount: number;
  outstandingAmount: number;
  discountAmount: number;
  discountPercentage: number;
  totalAmount: number;
  bookingStatus: string;
  invoiceUrl: string;
  noOfRooms: number;
  noOfPersons: number;
  checkinTime: string;
  checkoutTime: string;
  extraPersonCharge: number;
  noOfExtraPerson: number;
  roomDetails: RoomDetails[];
  bedDetails: BedDetails[];
  taxDetails: TaxDetails[];

  isWhatsApp: boolean;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  changeType: string;
  message: string;
  noOfNights: number;
  expectedNights: number;
  roomBooking: Boolean;
  roomRatePlanName: string;
  planCode: string;
  beforeTaxAmount: number;
  taxAmount: number;
  advanceAmount: number;
  paymentStatus: string;

  arrivingFrom: string;
  departingTo: string;
  purposeOfVisit: string;

  checkInDateStr: string;
  checkOutDateStr: string;
  roomNumbers: string;
  idDetails: string;

  counterName: string;
  counterNumber: string;
  operatorName: string;

  additionalGuests: Customer[];
  paymentDtoList: Payment[];

  extraChildCharge: number;
  noOfChildren: number;
  noOfExtraChild: number;
  invoiceId: string;
  customerCompanyName: string;

  totalBeforeTaxAmount: number;
  totalPayableAmount: number;
  bookingId: number;
  roomTariffBeforeDiscount: number;
  totalRoomTariffBeforeDiscount: number;
  totalBookingAmount: number;
  hsnCode: string;
  nationality: string;
  customerAddress: Address;
  includeService: boolean;

  fromTime: string;
  toTime: string;
  roomTariffPaid: number;
  roomTariffPending: number;
  serviceAmountPending: number;
  serviceAmountPaid: number;
  customerGstNo: string;
  customerImageurl: string;

  todoNotes: string;
  convenienceFee: number;
  bookingCommissionAmount: number;
  companyName: string;
  roomTariffAmountPending: number;
  roomTariffAmountPaid: number;
  propertyInvoiceNumber: number;

  tcsFee: number;
  tdsFee: number;

  groupBookingId : number;
  multiBookingInvoice : boolean;
  singleBookingInvoice : boolean;

  isGroupSingleRoomAllocation: boolean;
  expenseDtoList: Expense[];

  operatorNotes: string;
  auditType: string;
  checkoutPeriod: number;
  dateRollOver: boolean;
  twentyFourHoursCheckOut: boolean;
  showDiscount: boolean;
  otaBooking: boolean;
  // fromDate:string;
  // toDate:string;
  // externalSite:string;
  todosId: number;
  dateChangeCheckOutBookingTime: string;

  //
  propertyServices: PropertyServiceDTO[];
  onedayPlan: boolean;

  constructor() {}
}
