import { Component, OnInit } from '@angular/core';
import { TokenStorage } from 'app/token.storage';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public token: TokenStorage) { }

  ngOnInit() {
  }

}
