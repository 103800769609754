import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TokenStorage } from "../../token.storage";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: any;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  roles?: any;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}
interface UserRole {
  name: string; // Display text
}

@Injectable()
export class PropNavigationService {
  roleArray: any = [];
  userId: string;

  constructor(private token: TokenStorage) {
    this.userId = this.token.getUserId();
    // Logger.log('this.userId in navigation service--'+ this.userId +'-org Id-'+ this.token.getOrganizationId());
  }
  // tslint:disable-next-line: member-ordering
  iconMenu: IMenuItem[] = [
    {
      name: "Dashboard",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",

      // state: 'bookone/dashboard',
      state: "master-service/home",

      roles: [
        "PROP_ADMIN",
        "PROP_FRONTDESK",
        "PROP_MANAGER",
        "PROP_SERVICE",
        "PROP_HOUSEKEEPING",
        "PROP_FINANCE",
        "PROP_MARKETING",
        "PROP_FO_EXECUTIVE",
      ],
      // sub: [{ name: 'Dashboard', state: 'dashboard' }],
    },
    // {
    //   name: "Subscriptions",
    //   type: "dropDown",
    //   tooltip: "Subscriptions",
    //   icon: "book",
    //   roles: ["Subscriptions"],
    //   sub: [
    //     {
    //       name: "Property Management",
    //       type: "dropDown",
    //       icon: "room",
    //       state: "bookone",
    //       roles: [
    //         "ROOM MANAGEMENT",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //       ],
    //       sub: [
    //         { name: "Manage Property", state: "manage-property" },
    //         { name: "Manage Rooms", state: "manage-room" },
    //       ],
    //     },
    //     {
    //       name: "Manage Rooms",
    //       type: "link",
    //       icon: "room",
    //       state: "bookone/manage-room",
    //       roles: ["PROP_HOUSEKEEPING"],
    //       // sub: [{ name: "Manage Payment", state: "manage-payment" }],
    //     },
    //     // {
    //     //   name: "Property",
    //     //   type: "dropDown",
    //     //   icon: "room",
    //     //   state: "bookone",
    //     //   roles: ["PROPERTY MANAGEMENT"],
    //     //   sub: [
    //     //     //  { name: "Manage Property", state: "manage-property" },{ name: "Manage Rooms", state: "manage-room" }
    //     //   ],
    //     // },

    //     {
    //       name: "Bookings",
    //       type: "dropDown",
    //       icon: "book",
    //       state: "bookone",
    //       roles: [
    //         "BOOKING MANAGEMENT",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //         "PROP_MARKETING",
    //         "PROP_FO_EXECUTIVE",
    //       ],
    //       sub: [
    //         { name: "Manage Booking", state: "manage-booking" },
    //         { name: "Booking Calender", state: "booking-calender" },
    //       ],
    //     },
    //     {
    //       name: "Rates & Availability",
    //       type: "dropDown",
    //       icon: "event_available",
    //       state: "bookone",
    //       roles: [
    //         "BOOKING MANAGEMENT",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //         "PROP_MARKETING",
    //         "PROP_FO_EXECUTIVE",
    //       ],
    //       sub: [
    //         {
    //           name: "Manage Rates & Availability",
    //           state: "manage-rates-availability",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Manage Payment",
    //       type: "link",
    //       icon: "payment",
    //       state: "bookone/manage-payment",
    //       roles: [
    //         "Revenue Management",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_SERVICE",
    //         "PROP_FINANCE",
    //       ],
    //       // sub: [{ name: "Manage Payment", state: "manage-payment" }],
    //     },
    //     {
    //       name: "Manage Expense",
    //       type: "link",
    //       icon: "money_off",
    //       state: "bookone/manage-expense",
    //       roles: [
    //         "Revenue Management",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //       ],
    //       // sub: [{ name: "Manage Expense", state: "manage-expense" }],
    //     },
    //     {
    //       name: "External Reservations",
    //       type: "link",
    //       icon: "book",
    //       state: "bookone/external-reservations",
    //       roles: ["Channel Management"],
    //       // sub: [
    //       //   { name: "External Reservations", state: "external-reservations" },
    //       // ],
    //     },
    //     {
    //       name: "Customer",
    //       type: "dropDown",
    //       icon: "group",
    //       state: "bookone",
    //       roles: [
    //         "PROP_ADMIN",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //         "PROP_MARKETING",
    //       ],
    //       sub: [
    //         { name: "Add Customer", state: "add-customer" },
    //         { name: "Customer List", state: "customer-list" },
    //       ],
    //     },
    //     //  roles: ["Channel Management"],
    //     {
    //       name: "Channel Manager",
    //       type: "dropDown",
    //       icon: "book",
    //       state: "bookone-admin",
    //       roles: [
    //         "BOOKING MANAGEMENT",
    //         "PROP_ADMIN",
    //         "PROP_FRONTDESK",
    //         "PROP_MANAGER",
    //         "PROP_FINANCE",
    //       ],
    //       sub: [
    //         { name: "Property Details", state: "ota-property-details/0/0/0" },
    //       ],
    //     },
    //     // {
    //     //   name: 'Service On-boarding',
    //     //   type: 'dropDown',
    //     //   icon: 'book',
    //     //   state: 'master-service',
    //     //   roles: ['Service Management'],
    //     //   sub: [
    //     //      { name: 'Add A Service', state: 'service-onboard' },
    //     //   ],
    //     // },
    //     // {
    //     // name: "Service Management",
    //     // type: "dropDown",
    //     // icon: "book",
    //     // state: "master-service",
    //     // roles: ["Service Management"],
    //     // sub: [
    //     // { name: 'Add A Service', state: 'service-onboard' },
    //     // { name: 'All Service', state: 'service-list' },
    //     // { name: "Dashboard", state: "home" },
    //     // { name: 'Make A Reservation', state: 'make-reservation' },
    //     // { name: 'All Reservation', state: 'reservation-list' },
    //     // ],
    //     // },
    //     // {
    //     //   name: "Restaurant",
    //     //   type: "dropDown",
    //     //   icon: "book",
    //     //   state: "restaurant",
    //     //   roles: ["Restaurant Management"],
    //     //   sub: [{ name: "Restaurant Management", state: "home" }],
    //     // },

    //     {
    //       name: "Report",
    //       type: "dropDown",
    //       icon: "book",
    //       state: "report",
    //       roles: ["Reports", "PROP_MANAGER", "PROP_FINANCE"],
    //       sub: [
    //         { name: "Checkin/Police Report", state: "report-view" },
    //         { name: "Financial Summary Report", state: "financials-report" },
    //         { name: "Occupancy Report", state: "room-rateplan-report" },
    //         { name: "Arrival Report", state: "arrival-report" },
    //         { name: "Departure Report", state: "departure-report" },
    //         { name: "Cashier Report", state: "payment-report" },
    //         { name: "Room Status Report", state: "room-status-report" },
    //         { name: "GST Report", state: "gst-report" },
    //         { name: "Forecast Report", state: "forcast-report" },
    //         { name: "Product Report", state: "product-report" },
    //         { name: "Guest In House Report", state: "guest-in-house" },
    //         // { name: "Plan Report", state: "plan-report" },
    //         {
    //           name: "Channel Productivity Report",
    //           state: "productivity-report",
    //         },
    //         // { name: "Package Report", state: "package-report" },
    //         { name: "Add On Service Report", state: "service-report" },
    //         { name: "Expense Report", state: "expense-report" },
    //         { name: "Order Report", state: "order-report" },
    //         { name: "Booking Report", state: "booking-report" },
    //         { name: "Night Audit Report", state: "nightaudit-report" },
    //       ],
    //     },
    //     {
    //       name: "Report",
    //       type: "dropDown",
    //       icon: "book",
    //       state: "report",
    //       roles: ["PROP_HOUSEKEEPING"],
    //       sub: [
    //         { name: "Arrival Report", state: "arrival-report" },
    //         { name: "Departure Report", state: "departure-report" },
    //         { name: "Room Status Report", state: "room-status-report" },
    //       ],
    //     },
    //     {
    //       name: "Settings",
    //       type: "dropDown",
    //       icon: "room",
    //       state: "master-service",
    //       roles: ["Business Setup"],
    //       sub: [
    //         { name: "Business Profile", state: "business-dashboard/profile" },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   name: "On Boarding",
    //   type: "dropDown",
    //   tooltip: "Book",
    //   icon: "book",
    //   state: "onboarding",
    //   roles: ["Onboarding"],
    //   sub: [
    //     {
    //       name: "Add Property",
    //       roles: ["PROPERTY"],
    //       state: "property/0/0",
    //     },
    //     { name: "Add Room", roles: ["ROOM"], state: "room" },
    //   ],
    // },

    // {
    //   name: "Channel Manager",
    //   type: "dropDown",
    //   tooltip: "Bookone Admin",
    //   icon: "book",
    //   state: "bookone-admin",
    //   roles:
    //      [
    //       "channel_manager",
    //      ],
    //   sub: [
    //     { name: "Property Details", state: "ota-property-details/0/0/0" }

    //   ]
    // },
    {
      name: "",
      type: "",
      tooltip: "Bookone Admin",
      icon: "",
      roles: ["PROP_FO_EXECUTIVE"],
      sub: [
        {
          name: "B2B",
          type: "dropDown",
          icon: "book",
          roles: ["PROP_FO_EXECUTIVE"],
          state: "bookone-admin",
          sub: [{ name: "Add Company", state: "companyList" }],
        },
      ],
    },
    {
      name: "Property Admin",
      type: "dropDown",
      tooltip: "Bookone Admin",
      icon: "book",
      roles: ["PROP_ADMIN"],
      sub: [
        // {
        //   name: "User Management",
        //   type: "dropDown",
        //   icon: "book",
        //   roles: ["PROP_ADMIN"],
        //   state: "bookone-admin",
        //   sub: [
        //     { name: "Add User", state: "create-user/0/0/0" },
        //     { name: "Manage User", state: "manage-user" },
        //   ],
        // },
        {
          name: "B2B",
          type: "dropDown",
          icon: "book",
          roles: ["PROP_ADMIN"],
          state: "bookone-admin",
          sub: [{ name: "Add Company", state: "companyList" }],
        },
        {
          name: "Business Lead",
          type: "dropDown",
          icon: "book",
          roles: ["PROP_ADMIN"],
          state: "businessLead",
          sub: [{ name: "Business Lead", state: "manage-businesslead" }],
        },


      ],
    },
    {
      name: "",
      type: "dropDown",
      tooltip: "Bookone Admin",
      icon: "book",
      roles: ["PROP_FRONTDESK", "PROP_MANAGER"],
      sub: [
        {
          name: "B2B",
          type: "dropDown",
          icon: "book",
          roles: ["PROP_FRONTDESK", "PROP_MANAGER"],
          state: "bookone-admin",
          sub: [{ name: "Add Company", state: "companyList" }],
        },
        {
          name: "Manage Service Cases",
          type: "link",
          icon: "receipt",
          roles: ["PROP_FRONTDESK"],
          state: "bookone-admin/manage-service-request",
          // sub: [{ name: 'Order', state: 'order-list' }],
        },
      ],
    },
    {
      name: "Business Promotion",
      type: "dropDown",
      icon: "receipt",
      state: "business-promotion",
      roles: ["PROP_ADMIN", "PROP_MARKETING"],
      sub: [{ name: "Business Promotion", state: "list" }],
    },
    {
      name: "Business Review",
      type: "dropDown",
      icon: "receipt",
      state: "review",
      roles: ["PROP_ADMIN"],
      sub: [{ name: "Review List", state: "list" }],
    },

  ];

  onBoardingURL(): string {
    return (
      "property/" +
      this.token.getUserId() +
      "/" +
      this.token.getOrganizationId() +
      ""
    );
  }

  // tslint:disable-next-line: member-ordering
  separatorMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "bookone",
    },
  ];

  // tslint:disable-next-line: member-ordering
  plainMenu: IMenuItem[] = [
    {
      name: "BOOKONE",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard",
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
