import { Expense } from "../manage-expense/expense/expense";
import { Payment } from "../payment/payment";
import { Service } from "../service/service";
import { Booking } from "./booking";

export class BookingCalculation {
  calculateBookingBalance(
    payments: Payment[],
    booking: Booking,
    services: Service[],
    expenses: Expense[]
  ) {
    let bookingPayment = [];
    let deletePayment = [];
    let expensesList = [];

    let balanceAmount = 0;
    let advanceBalance = 0;

    let roomPaymentDTO = this.roomPaymentBalanceCalculation(payments, booking);

    if (roomPaymentDTO != null) {
      for (let i = 0; i < roomPaymentDTO.booking.length; i++) {
        if (roomPaymentDTO.booking[i].transactionAmount > 0) {
          bookingPayment.push(roomPaymentDTO.booking[i]);
        }
      }

      for (let i = 0; i < roomPaymentDTO.delete.length; i++) {
        if (roomPaymentDTO.delete[i].id != null) {
          deletePayment.push(roomPaymentDTO.delete[i]);
        }
      }

      balanceAmount = roomPaymentDTO.balanceAmount;
      advanceBalance = roomPaymentDTO.advanceBalance;
    }

    let servicePaymentDTO = this.servicePaymentBalanceCalculation(
      payments,
      services,
      balanceAmount,
      advanceBalance
    );

    if (servicePaymentDTO != null) {
      for (let i = 0; i < servicePaymentDTO.booking.length; i++) {
        if (servicePaymentDTO.booking[i].transactionAmount > 0) {
          bookingPayment.push(servicePaymentDTO.booking[i]);
        }
      }
      for (let i = 0; i < servicePaymentDTO.delete.length; i++) {
        if (servicePaymentDTO.delete[i].id != null) {
          deletePayment.push(servicePaymentDTO.delete[i]);
        }
      }

      balanceAmount = servicePaymentDTO.balanceAmount;
      advanceBalance = servicePaymentDTO.advanceBalance;
    }

    let expensePaymentDTO = this.expensePaymentBalanceCalculation(
      payments,
      expenses,
      balanceAmount,
      advanceBalance
    );

    if (expensePaymentDTO != null) {
      for (let i = 0; i < expensePaymentDTO.booking.length; i++) {
        if (expensePaymentDTO.booking[i].transactionAmount > 0) {
          bookingPayment.push(expensePaymentDTO.booking[i]);
        }
      }
      for (let i = 0; i < expensePaymentDTO.delete.length; i++) {
        if (expensePaymentDTO.delete[i].id != null) {
          deletePayment.push(expensePaymentDTO.delete[i]);
        }
      }

      for (let i = 0; i < expensePaymentDTO.expenses.length; i++) {
        expensesList.push(expensePaymentDTO.expenses[i]);
      }

      balanceAmount = expensePaymentDTO.balanceAmount;
      advanceBalance = expensePaymentDTO.advanceBalance;
    }

    let roomBalancePaymentDTO = this.setRoomBalanceAmount(
      payments,
      balanceAmount,
      advanceBalance
    );
    if (roomBalancePaymentDTO != null) {
      for (let i = 0; i < roomBalancePaymentDTO.length; i++) {
        bookingPayment.push(roomBalancePaymentDTO[i]);
      }
    }

    let payment = {
      booking: bookingPayment,
      delete: deletePayment,
      balanceAmount: balanceAmount,
      expenses: expensesList,
    };

    return payment;
  }

  roomPaymentBalanceCalculation(payments: Payment[], booking: Booking) {
    let roomPrice = booking.payableAmount;
    let roomPricePaid = this.getRoomAmountPaid(payments);

    let balanceAmount = 0;
    let advanceBalance = 0;

    let bookingPayment = [];
    let deletePayment = [];

    // rooom balance
    if (roomPricePaid > roomPrice) {
      let roomPriceBalance = roomPricePaid - roomPrice;

      if (this.getTotalRoomPaidListByPaymentType(payments, false).length > 0) {
        let balance = 0;
        for (
          let i = 0;
          i < this.getTotalRoomPaidListByPaymentType(payments, false).length;
          i++
        ) {
          balance =
            balance +
            this.getTotalRoomPaidListByPaymentType(payments, false)[i]
              .transactionAmount;
        }

        for (
          let i = 0;
          i < this.getTotalRoomPaidListByPaymentType(payments, false).length;
          i++
        ) {
          if (i === 0) {
            if (balance >= roomPriceBalance) {
              this.getTotalRoomPaidListByPaymentType(payments, false)[
                i
              ].transactionAmount = balance - roomPriceBalance;
              bookingPayment.push(
                this.getTotalRoomPaidListByPaymentType(payments, false)[i]
              );
              balanceAmount = roomPriceBalance;
              roomPriceBalance = 0;
            } else {
              this.getTotalRoomPaidListByPaymentType(payments, false)[
                i
              ].transactionAmount = 0;
              deletePayment.push(
                this.getTotalRoomPaidListByPaymentType(payments, false)[i]
              );
              roomPriceBalance = roomPriceBalance - balance;
              balanceAmount = balance;
            }
          } else {
            deletePayment.push(
              this.getTotalRoomPaidListByPaymentType(payments, false)[i]
            );
          }
        }
      }

      if (this.getTotalRoomPaidListByPaymentType(payments, true).length > 0) {
        let balance = 0;
        for (
          let i = 0;
          i < this.getTotalRoomPaidListByPaymentType(payments, true).length;
          i++
        ) {
          balance =
            balance +
            this.getTotalRoomPaidListByPaymentType(payments, true)[i]
              .transactionAmount;
        }
        for (
          let i = 0;
          i < this.getTotalRoomPaidListByPaymentType(payments, true).length;
          i++
        ) {
          if (i === 0) {
            if (balance >= roomPriceBalance) {
              this.getTotalRoomPaidListByPaymentType(payments, true)[
                i
              ].transactionAmount = balance - roomPriceBalance;
              bookingPayment.push(
                this.getTotalRoomPaidListByPaymentType(payments, true)[i]
              );
              advanceBalance = roomPriceBalance;
              roomPriceBalance = 0;
            } else {
              this.getTotalRoomPaidListByPaymentType(payments, true)[
                i
              ].transactionAmount = 0;
              deletePayment.push(
                this.getTotalRoomPaidListByPaymentType(payments, true)[i]
              );
              roomPriceBalance = roomPriceBalance - balance;
              advanceBalance = balance;
            }
          } else {
            deletePayment.push(
              this.getTotalRoomPaidListByPaymentType(payments, true)[i]
            );
          }
        }
      }

      let payment = {
        booking: bookingPayment,
        delete: deletePayment,
        balanceAmount: balanceAmount,
        advanceBalance: advanceBalance,
      };

      return payment;
    } else {
      return null;
    }
  }
  servicePaymentBalanceCalculation(
    payments: Payment[],
    services: Service[],
    balanceAmount: number,
    advancedBalance: number
  ) {
    let bookingPayment = [];
    let deletePayment = [];

    if (services != null && services != undefined && services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (services[i].orderId != null) {
          //------------------
          if (
            this.getServicePaidAmountByOrderId(payments, services[i]) >
            services[i].afterTaxAmount
          ) {
            let balanceService =
              this.getServicePaidAmountByOrderId(payments, services[i]) -
              services[i].afterTaxAmount;
            this.getServicePaidListByOrderId(payments, services[i]).sort(
              function (a, b) {
                return b.transactionAmount - a.transactionAmount;
              }
            );

            let servicePaidListByOrderId = this.getServicePaidListByOrderId(
              payments,
              services[i]
            );
            for (let k = 0; k < servicePaidListByOrderId.length; k++) {
              if (balanceService > 0) {
                if (
                  servicePaidListByOrderId[k].transactionAmount > balanceService
                ) {
                  servicePaidListByOrderId[k].transactionAmount =
                    servicePaidListByOrderId[k].transactionAmount -
                    balanceService;
                  if (
                    servicePaidListByOrderId[k].advancePayment != null &&
                    servicePaidListByOrderId[k].advancePayment === true
                  ) {
                    servicePaidListByOrderId[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceService;
                  } else {
                    servicePaidListByOrderId[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceService;
                  }
                  balanceService = 0;
                  bookingPayment.push(servicePaidListByOrderId[k]);
                } else {
                  balanceService =
                    balanceService -
                    servicePaidListByOrderId[k].transactionAmount;
                  if (
                    servicePaidListByOrderId[k].advancePayment != null &&
                    servicePaidListByOrderId[k].advancePayment === true
                  ) {
                    servicePaidListByOrderId[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceService;
                  } else {
                    servicePaidListByOrderId[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceService;
                  }

                  servicePaidListByOrderId[k].transactionAmount = 0;
                  deletePayment.push(servicePaidListByOrderId[k]);
                }
              }
            }
          } else {
            let notPaidAmount =
              services[i].afterTaxAmount -
              this.getServicePaidAmountByOrderId(payments, services[i]);
            let serviceNotPaid = this.getServiceNotPaidListByOrderId(
              payments,
              services[i]
            );
            if (serviceNotPaid.length > 0) {
              for (let j = 0; j < serviceNotPaid.length; j++) {
                if (j === 0) {
                  if (balanceAmount > 0) {
                    if (notPaidAmount > balanceAmount) {
                      let amount = notPaidAmount - balanceAmount;
                      serviceNotPaid[j].transactionAmount = amount;
                      serviceNotPaid[j].paymentMode = "Cash";
                      serviceNotPaid[j].status = "Paid";
                      serviceNotPaid[j].advancePayment = false;
                      bookingPayment.push(serviceNotPaid[j]);

                      let notPaidAmt = services[i].afterTaxAmount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(serviceNotPaid[j], notPaidAmt)
                      );

                      balanceAmount = 0;
                      notPaidAmount = notPaidAmount - balanceAmount;
                    } else {
                      serviceNotPaid[j].id = null;
                      serviceNotPaid[j].billNo = null;
                      serviceNotPaid[j].transactionAmount = notPaidAmount;
                      serviceNotPaid[j].status = "Paid";
                      serviceNotPaid[j].paymentMode = this.getPaymentMode(
                        serviceNotPaid[j].paymentMode
                      );
                      serviceNotPaid[j].advancePayment = false;

                      bookingPayment.push(serviceNotPaid[j]);
                      balanceAmount = balanceAmount - notPaidAmount;
                      notPaidAmount = 0;
                    }
                  }

                  if (advancedBalance > 0) {
                    if (notPaidAmount > advancedBalance) {
                      let amount = notPaidAmount - advancedBalance;
                      serviceNotPaid[j].transactionAmount = amount;
                      serviceNotPaid[j].paymentMode = "Cash";
                      serviceNotPaid[j].status = "Paid";
                      serviceNotPaid[j].advancePayment = true;
                      bookingPayment.push(serviceNotPaid[j]);

                      let notPaidAmt = services[i].afterTaxAmount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(serviceNotPaid[j], notPaidAmt)
                      );

                      advancedBalance = 0;
                      notPaidAmount = notPaidAmount - advancedBalance;
                    } else {
                      serviceNotPaid[j].id = null;
                      serviceNotPaid[j].billNo = null;
                      serviceNotPaid[j].transactionAmount = notPaidAmount;
                      serviceNotPaid[j].status = "Paid";
                      serviceNotPaid[j].paymentMode = this.getPaymentMode(
                        serviceNotPaid[j].paymentMode
                      );
                      serviceNotPaid[j].advancePayment = true;
                      bookingPayment.push(serviceNotPaid[j]);
                      advancedBalance = advancedBalance - notPaidAmount;
                      notPaidAmount = 0;
                    }
                  }
                }
                // else {
                //   deletePayment.push(this.getServiceNotPaidListById(payments, services[i])[j]);
                // }
              }
            }
            // else
            // {
            // payment not created
            // }
          }
          //-----------------------------------
        } else {
          if (
            this.getServicePaidAmountById(payments, services[i]) >
            services[i].afterTaxAmount
          ) {
            let balanceService =
              this.getServicePaidAmountById(payments, services[i]) -
              services[i].afterTaxAmount;
            this.getServicePaidListById(payments, services[i]).sort(function (
              a,
              b
            ) {
              return b.transactionAmount - a.transactionAmount;
            });

            let servicePaidList = this.getServicePaidListById(
              payments,
              services[i]
            );
            for (let k = 0; k < servicePaidList.length; k++) {
              if (balanceService > 0) {
                if (servicePaidList[k].transactionAmount > balanceService) {
                  servicePaidList[k].transactionAmount =
                    servicePaidList[k].transactionAmount - balanceService;
                  if (
                    servicePaidList[k].advancePayment != null &&
                    servicePaidList[k].advancePayment === true
                  ) {
                    servicePaidList[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceService;
                  } else {
                    servicePaidList[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceService;
                  }
                  balanceService = 0;
                  bookingPayment.push(servicePaidList[k]);
                } else {
                  balanceService =
                    balanceService - servicePaidList[k].transactionAmount;
                  if (
                    servicePaidList[k].advancePayment != null &&
                    servicePaidList[k].advancePayment === true
                  ) {
                    servicePaidList[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceService;
                  } else {
                    servicePaidList[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceService;
                  }

                  servicePaidList[k].transactionAmount = 0;
                  deletePayment.push(servicePaidList[k]);
                }
              }
            }
          } else {
            let notPaidList = this.getServiceNotPaidListById(
              payments,
              services[i]
            );
            let notPaidAmount =
              services[i].afterTaxAmount -
              this.getServicePaidAmountById(payments, services[i]);
            if (notPaidList.length > 0) {
              for (let j = 0; j < notPaidList.length; j++) {
                if (j === 0) {
                  if (balanceAmount > 0) {
                    if (notPaidAmount > balanceAmount) {
                      let amount = notPaidAmount - balanceAmount;
                      notPaidList[j].transactionAmount = amount;
                      notPaidList[j].paymentMode = "Cash";
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = false;
                      bookingPayment.push(notPaidList[j]);

                      let notPaidAmt = services[i].afterTaxAmount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(notPaidList[j], notPaidAmt)
                      );

                      balanceAmount = 0;
                      notPaidAmount = notPaidAmount - balanceAmount;
                    } else {
                      notPaidList[j].id = null;
                      notPaidList[j].billNo = null;
                      notPaidList[j].transactionAmount = notPaidAmount;
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = false;
                      notPaidList[j].paymentMode = this.getPaymentMode(
                        notPaidList[j].paymentMode
                      );
                      bookingPayment.push(notPaidList[j]);
                      balanceAmount = balanceAmount - notPaidAmount;
                      notPaidAmount = 0;
                    }
                  }

                  if (advancedBalance > 0) {
                    if (notPaidAmount > advancedBalance) {
                      let amount = notPaidAmount - advancedBalance;
                      notPaidList[j].transactionAmount = amount;
                      notPaidList[j].paymentMode = "Cash";
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = true;
                      bookingPayment.push(notPaidList[j]);

                      let notPaidAmt = services[i].afterTaxAmount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(notPaidList[j], notPaidAmt)
                      );

                      advancedBalance = 0;
                      notPaidAmount = notPaidAmount - advancedBalance;
                    } else {
                      notPaidList[j].id = null;
                      notPaidList[j].billNo = null;
                      notPaidList[j].transactionAmount = notPaidAmount;
                      notPaidList[j].status = "Paid";
                      notPaidList[j].paymentMode = this.getPaymentMode(
                        notPaidList[j].paymentMode
                      );
                      notPaidList[j].advancePayment = true;
                      bookingPayment.push(notPaidList[j]);
                      advancedBalance = advancedBalance - notPaidAmount;
                      notPaidAmount = 0;
                    }
                  }
                }
                // else {
                //   deletePayment.push(this.getServiceNotPaidListById(payments, services[i])[j]);
                // }
              }
            }
            // else
            // {
            // payment not created
            // }
          }
        }

        if (services[i].orderId != null) {
          let zeroServicePayment = this.getServicePaidZeroAmountByOrderId(
            payments,
            services[i]
          );
          if (zeroServicePayment.length > 0 && services[i].afterTaxAmount > 0) {
            for (let z = 0; z < zeroServicePayment.length; z++) {
              deletePayment.push(zeroServicePayment[z]);
            }
          }
        } else {
          let zeroServicePayment = this.getServicePaidZeroAmountByServiceId(
            payments,
            services[i]
          );
          if (zeroServicePayment.length > 0 && services[i].afterTaxAmount > 0) {
            for (let z = 0; z < zeroServicePayment.length; z++) {
              deletePayment.push(zeroServicePayment[z]);
            }
          }
        }
      }

      let payment = {
        booking: bookingPayment,
        delete: deletePayment,
        balanceAmount: balanceAmount,
        advanceBalance: advancedBalance,
      };

      return payment;
    } else {
      return null;
    }
  }
  expensePaymentBalanceCalculation(
    payments: Payment[],
    expenses: Expense[],
    balanceAmount: number,
    advancedBalance: number
  ) {
    let bookingPayment = [];
    let deletePayment = [];

    if (expenses != null && expenses != undefined && expenses.length > 0) {
      for (let i = 0; i < expenses.length; i++) {
        if (
          expenses[i].chargeAbleToBooking === null ||
          expenses[i].chargeAbleToBooking === undefined ||
          expenses[i].chargeAbleToBooking === false
        ) {
          if (
            this.getExpensePaidAmountById(payments, expenses[i]) >
            expenses[i].amount
          ) {
            let balanceExpense =
              this.getExpensePaidAmountById(payments, expenses[i]) -
              expenses[i].amount;
            this.getExpensePaidListById(payments, expenses[i]).sort(function (
              a,
              b
            ) {
              return b.transactionAmount - a.transactionAmount;
            });

            let expensePaidList = this.getExpensePaidListById(
              payments,
              expenses[i]
            );
            for (let k = 0; k < expensePaidList.length; k++) {
              if (balanceExpense > 0) {
                expenses[i].paidAmount = expenses[i].amount;
                expenses[i].balanceAmount = 0;

                if (expensePaidList[k].transactionAmount > balanceExpense) {
                  expensePaidList[k].transactionAmount =
                    expensePaidList[k].transactionAmount - balanceExpense;
                  if (
                    expensePaidList[k].advancePayment != null &&
                    expensePaidList[k].advancePayment === true
                  ) {
                    expensePaidList[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceExpense;
                  } else {
                    expensePaidList[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceExpense;
                  }
                  balanceExpense = 0;
                  bookingPayment.push(expensePaidList[k]);
                } else {
                  balanceExpense =
                    balanceExpense - expensePaidList[k].transactionAmount;
                  if (
                    expensePaidList[k].advancePayment != null &&
                    expensePaidList[k].advancePayment === true
                  ) {
                    expensePaidList[k].advancePayment = true;
                    advancedBalance = advancedBalance + balanceExpense;
                  } else {
                    expensePaidList[k].advancePayment = false;
                    balanceAmount = balanceAmount + balanceExpense;
                  }

                  expensePaidList[k].transactionAmount = 0;
                  deletePayment.push(expensePaidList[k]);
                }
              }
            }
          } else {
            let paidAmountExpense = this.getExpensePaidAmountById(
              payments,
              expenses[i]
            );
            let notPaidList = this.getExpenseNotPaidListById(
              payments,
              expenses[i]
            );
            let notPaidAmount = expenses[i].amount - paidAmountExpense;
            if (notPaidList.length > 0) {
              for (let j = 0; j < notPaidList.length; j++) {
                if (j === 0) {
                  if (balanceAmount > 0) {
                    if (notPaidAmount > balanceAmount) {
                      let amount = notPaidAmount - balanceAmount;
                      notPaidList[j].transactionAmount = amount;
                      notPaidList[j].paymentMode = "Cash";
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = false;
                      bookingPayment.push(notPaidList[j]);

                      let notPaidAmt = expenses[i].amount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(notPaidList[j], notPaidAmt)
                      );

                      balanceAmount = 0;
                      notPaidAmount = notPaidAmount - balanceAmount;

                      expenses[i].paidAmount = amount + paidAmountExpense;
                      expenses[i].balanceAmount =
                        expenses[i].amount - expenses[i].paidAmount;
                    } else {
                      notPaidList[j].id = null;
                      notPaidList[j].billNo = null;
                      notPaidList[j].transactionAmount = notPaidAmount;
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = false;
                      notPaidList[j].paymentMode = this.getPaymentMode(
                        notPaidList[j].paymentMode
                      );
                      bookingPayment.push(notPaidList[j]);
                      balanceAmount = balanceAmount - notPaidAmount;
                      notPaidAmount = 0;

                      expenses[i].paidAmount = expenses[i].amount;
                      expenses[i].balanceAmount = 0;
                    }
                  }

                  if (advancedBalance > 0) {
                    if (notPaidAmount > advancedBalance) {
                      let amount = notPaidAmount - advancedBalance;
                      notPaidList[j].transactionAmount = amount;
                      notPaidList[j].paymentMode = "Cash";
                      notPaidList[j].status = "Paid";
                      notPaidList[j].advancePayment = true;
                      bookingPayment.push(notPaidList[j]);

                      let notPaidAmt = expenses[i].amount - amount;
                      bookingPayment.push(
                        this.setNotPaidService(notPaidList[j], notPaidAmt)
                      );

                      advancedBalance = 0;
                      notPaidAmount = notPaidAmount - advancedBalance;

                      expenses[i].paidAmount = amount + paidAmountExpense;
                      expenses[i].balanceAmount =
                        expenses[i].amount - expenses[i].paidAmount;
                    } else {
                      notPaidList[j].id = null;
                      notPaidList[j].billNo = null;
                      notPaidList[j].transactionAmount = notPaidAmount;
                      notPaidList[j].status = "Paid";
                      notPaidList[j].paymentMode = this.getPaymentMode(
                        notPaidList[j].paymentMode
                      );
                      notPaidList[j].advancePayment = true;
                      bookingPayment.push(notPaidList[j]);
                      advancedBalance = advancedBalance - notPaidAmount;
                      notPaidAmount = 0;

                      expenses[i].paidAmount = expenses[i].amount;
                      expenses[i].balanceAmount = 0;
                    }
                  }
                }
                // else {
                //   deletePayment.push(this.getServiceNotPaidListById(payments, services[i])[j]);
                // }
              }
            }
            // else
            // {
            // payment not created
            // }
          }

          let zeroExpensePayment = this.getExpensePaidZeroAmountByExpenseId(
            payments,
            expenses[i]
          );
          if (zeroExpensePayment.length > 0 && expenses[i].amount > 0) {
            for (let z = 0; z < zeroExpensePayment.length; z++) {
              deletePayment.push(zeroExpensePayment[z]);
            }
          }
        }
      }
      let payment = {
        booking: bookingPayment,
        delete: deletePayment,
        balanceAmount: balanceAmount,
        advanceBalance: advancedBalance,
        expenses: expenses,
      };

      return payment;
    } else {
      return null;
    }
  }

  setRoomBalanceAmount(payments, balanceAmount, advancedBalance) {
    let bookingPayment = [];

    if (balanceAmount > 0 && this.getTotalRoomPaidList(payments).length > 0) {
      bookingPayment.push(
        this.setPaidRoomBalance(
          this.getTotalRoomPaidList(payments)[0],
          balanceAmount,
          false
        )
      );
    }

    if (advancedBalance > 0 && this.getTotalRoomPaidList(payments).length > 0) {
      bookingPayment.push(
        this.setPaidRoomBalance(
          this.getTotalRoomPaidList(payments)[0],
          balanceAmount,
          true
        )
      );
    }

    return bookingPayment;
  }

  setPaidRoomBalance(payment, paidAmount, advancePayment) {
    let roomBalancePaidpayment = new Payment();

    roomBalancePaidpayment.referenceNumber = payment.referenceNumber;
    roomBalancePaidpayment.name = payment.name;
    roomBalancePaidpayment.email = payment.email;
    roomBalancePaidpayment.businessEmail = payment.businessEmail;
    roomBalancePaidpayment.currency = payment.currency;
    roomBalancePaidpayment.description = payment.description;
    roomBalancePaidpayment.propertyId = payment.propertyId;
    roomBalancePaidpayment.date = payment.date;
    roomBalancePaidpayment.businessServiceName = payment.businessServiceName;
    roomBalancePaidpayment.counterNumber = payment.counterNumber;
    roomBalancePaidpayment.counterName = payment.counterName;
    roomBalancePaidpayment.operatorName = payment.operatorName;
    roomBalancePaidpayment.serviceId = payment.serviceId;
    roomBalancePaidpayment.companyName = payment.companyName;
    roomBalancePaidpayment.orderId = payment.orderId;
    roomBalancePaidpayment.expenseId = payment.expenseId;
    roomBalancePaidpayment.advancePayment = advancePayment;
    roomBalancePaidpayment.creditSettled = payment.creditSettled;
    roomBalancePaidpayment.chargeAbleToBooking = payment.chargeAbleToBooking;
    roomBalancePaidpayment.transactionAmount = paidAmount;
    roomBalancePaidpayment.paymentMode = "Cash";
    roomBalancePaidpayment.status = "Paid";
    return roomBalancePaidpayment;
  }
  setNotPaidService(service, notPaidamount) {
    let newNotPaidService = new Payment();

    newNotPaidService.referenceNumber = service.referenceNumber;
    newNotPaidService.name = service.name;
    newNotPaidService.email = service.email;
    newNotPaidService.businessEmail = service.businessEmail;
    newNotPaidService.currency = service.currency;
    newNotPaidService.description = service.description;
    newNotPaidService.propertyId = service.propertyId;
    newNotPaidService.date = service.date;
    newNotPaidService.businessServiceName = service.businessServiceName;
    newNotPaidService.counterNumber = service.counterNumber;
    newNotPaidService.counterName = service.counterName;
    newNotPaidService.operatorName = service.operatorName;
    newNotPaidService.serviceId = service.serviceId;
    newNotPaidService.companyName = service.companyName;
    newNotPaidService.orderId = service.orderId;
    newNotPaidService.expenseId = service.expenseId;
    newNotPaidService.advancePayment = service.advancePayment;
    newNotPaidService.creditSettled = service.creditSettled;
    newNotPaidService.chargeAbleToBooking = service.chargeAbleToBooking;
    newNotPaidService.transactionAmount = notPaidamount;
    newNotPaidService.paymentMode = "Cash";
    newNotPaidService.status = "NotPaid";
    return newNotPaidService;
  }
  getPaymentMode(mode) {
    if (
      (mode != null && mode === "BillToRoom") ||
      (mode != null && mode === "Credit")
    ) {
      return "Cash";
    } else {
      return mode;
    }
  }

  //

  getAllBookingCreditAmount(payments) {
    if(payments != null && payments != undefined){
      return this.getTotalCreditPaymentByBookingPayment(payments);
    }
    else
    {
      return 0;
    }
  }

  getSingleExpensePaidAmount(payments, espenseId) {
    return this.getTotalExpensePaidPaymentByExpenseId(payments, espenseId);
  }

  //
  getDueAmount(booing: any, payments: any[]) {
    if (this.getTotalNotPaidPayment(booing, payments) > 0) {
      return this.getTotalNotPaidPayment(booing, payments);
    } else {
      return 0;
    }
  }

  getTotalNotPaidPayment(booing: any, payments: any[]) {
    return (
      this.getTotalPaymentAmount(booing, payments) -
      this.getTotalPaidPayment(payments) -
      this.getCommitionAmount(booing)
    );
  }

  getTotalPaidPayment(payments: any[]) {
    return (
      this.getRoomAmountPaid(payments) +
      this.getExpenceAmountPaid(payments) +
      this.getServiceAmountPaid(payments) -
      this.getPaidRefundPayment(payments)
    );
  }

  getCancelBookingPaidAmount(payments) {
    return (
      this.getRoomAmountPaid(payments) +
      this.getServiceAmountPaid(payments) +
      this.getTotalExpensePaidWOTRefundAmount(payments) +
      this.getPaidRefundPayment(payments)
    );
  }

  roomPrice(booking) {
    return booking.payableAmount;
  }

  // room
  getRoomAmountPaid(payments: any[]) {
    return this.getTotalRoomPaidPaymentByBookingPayment(payments);
  }

  getRoomAmountNotPaid(booking: any, payments: any[]) {
    return (
      booking.payableAmount -
      this.getTotalRoomPaidPaymentByBookingPayment(payments)
    );
  }

  getPaidRefundPayment(payments: any[]) {
    return this.getTotalExpenseRefundPaidPaymentByBookingPayment(payments);
  }

  getServiceAmountNotPaid(booking: any, payments: any[]) {
    return (
      booking.totalServiceAmount -
      this.getTotalServicePaidPaymentByBookingPayment(payments)
    );
  }

  getServiceAmountPaid(payments: any[]) {
    return this.getTotalServicePaidPaymentByBookingPayment(payments);
  }

  getExpenceAmountNotPaid(booking, payments: any[]) {
    return (
      booking.totalExpenseAmount -
      this.getTotalExpensePaidPaymentByBookingPayment(payments)
    );
  }

  getExpenceAmountPaid(payments) {
    return this.getTotalExpensePaidPaymentByBookingPayment(payments);
  }

  getTotalPaymentAmount(booking, payments) {
    return (
      this.getAmount(booking.totalServiceAmount) +
      this.getAmount(booking.payableAmount) +
      this.getAmount(booking.totalExpenseAmount) -
      //this.getChargeAppliedInBooking() -
      this.getBookingRefundAmount(payments)
    );
  }

  getBookingRefundAmount(payments) {
    return this.getTotalExpenseBookingRefundPaymentByBookingPayment(payments);
  }

  getCommitionAmount(booking) {
    return (
      this.getAmount(booking.bookingCommissionAmount) +
      this.getAmount(booking.tcsFee) +
      this.getAmount(booking.tdsFee)
    );
  }

  hotelCollectAmount(booking) {
    return (
      this.getAmount(booking.payableAmount) - this.getCommitionAmount(booking)
    );
  }

  getChargeAppliedInBooking(payments) {
    return this.getTotalExpenseBookingChargeAmountByBookingPayment(payments);
  }

  //.........................
  getAmount(data) {
    if (data != null && data != undefined) {
      return data;
    } else {
      return 0;
    }
  }

  getTotalPaymentAmountByMOPAndStatus(
    paymentMode: string,
    status: string,
    payments: any[]
  ) {
    let sum = 0;

    if (
      this.getPaymentDataByModeOfPaymentAndStatus(
        paymentMode,
        status,
        payments
      ) != null &&
      this.getPaymentDataByModeOfPaymentAndStatus(
        paymentMode,
        status,
        payments
      ) != undefined &&
      this.getPaymentDataByModeOfPaymentAndStatus(paymentMode, status, payments)
        .length > 0
    ) {
      for (
        let i = 0;
        i <
        this.getPaymentDataByModeOfPaymentAndStatus(
          paymentMode,
          status,
          payments
        ).length;
        i++
      ) {
        sum =
          sum +
          this.getPaymentDataByModeOfPaymentAndStatus(
            paymentMode,
            status,
            payments
          )[i].transactionAmount;
      }
    }
    return sum;
  }

  getPaymentDataByModeOfPaymentAndStatus(
    paymentMode: string,
    status: string,
    payments: any[]
  ) {
    let orderData = [];
    if (payments != null && payments != undefined && payments.length > 0) {
      orderData = payments.filter((item) => {
        const searchResult =
          item.paymentMode != null &&
          item.paymentMode === paymentMode &&
          item.status != null &&
          item.status === status;

        return searchResult;
      });
    }

    return orderData;
  }

  getTotalPaymentAmountByMOP(paymentMode: string, payments: any[]) {
    let sum = 0;

    if (
      this.getPaymentDataByModeOfPayment(paymentMode, payments) != null &&
      this.getPaymentDataByModeOfPayment(paymentMode, payments) != undefined &&
      this.getPaymentDataByModeOfPayment(paymentMode, payments).length > 0
    ) {
      for (
        let i = 0;
        i < this.getPaymentDataByModeOfPayment(paymentMode, payments).length;
        i++
      ) {
        sum =
          sum +
          this.getPaymentDataByModeOfPayment(paymentMode, payments)[i]
            .transactionAmount;
      }
    }
    return sum;
  }

  getPaymentDataByModeOfPayment(paymentMode: string, payments) {
    let orderData = [];
    if (payments != null && payments != undefined && payments.length > 0) {
      orderData = payments.filter((item) => {
        const searchResult =
          item.paymentMode != null && item.paymentMode === paymentMode;

        return searchResult;
      });
    }

    return orderData;
  }

  getTotalExpenseBookingChargeAmountByBookingPayment(paymentList) {
    let sum = 0;
    let chargePaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      chargePaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.chargeAbleToBooking != null &&
          item.chargeAbleToBooking == true;

        return searchResult;
      });
    }

    if (
      chargePaymentList != null &&
      chargePaymentList != undefined &&
      chargePaymentList.length > 0
    ) {
      for (let i = 0; i < chargePaymentList.length; i++) {
        sum = sum + chargePaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalExpenseBookingRefundPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.businessServiceName != null &&
          item.businessServiceName == "Booking Refund";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalExpensePaidPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalServicePaidPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode != "Credit" &&
            item.paymentMode != "BillToRoom") ||
          (item.orderId != null &&
            item.orderId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode != "Credit" &&
            item.paymentMode != "BillToRoom");

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalExpenseRefundPaidPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.businessServiceName != null &&
          item.businessServiceName == "Booking Refund" &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalRoomPaidPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId === null &&
          item.serviceId == null &&
          item.orderId == null &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getTotalExpensePaidPaymentByExpenseId(paymentList, expenseId) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.expenseId === expenseId &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  //

  getTotalServicePaidList(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode != "Credit" &&
            item.paymentMode != "BillToRoom") ||
          (item.orderId != null &&
            item.orderId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode != "Credit" &&
            item.paymentMode != "BillToRoom");

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getTotalExpenseNotPaidList(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.status != null &&
          item.status === "NotPaid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom" &&
          item.businessServiceName != null &&
          item.businessServiceName != "Booking Refund";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getTotalRoomPaidList(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId === null &&
          item.serviceId == null &&
          item.orderId == null &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getTotalRoomPaidListByPaymentType(paymentList, advancePayment) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId === null &&
          item.serviceId == null &&
          this.checkAdvancePayment(item.advancePayment) === advancePayment &&
          item.orderId == null &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  checkAdvancePayment(value) {
    if (value == null || value == undefined) {
      return false;
    } else {
      return value;
    }
  }

  getServiceDuePaymentList(paymentList) {
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.status != null &&
            item.status === "NotPaid") ||
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode === "Credit") ||
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode === "BillToRoom");

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getOrderDueServiceList(paymentList) {
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.orderId != null &&
            item.orderId != undefined &&
            item.status != null &&
            item.status === "NotPaid") ||
          (item.orderId != null &&
            item.orderId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode === "Credit") ||
          (item.orderId != null &&
            item.orderId != undefined &&
            item.status != null &&
            item.status === "Paid" &&
            item.paymentMode != null &&
            item.paymentMode === "BillToRoom");

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getServiceBalanceCheck(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.serviceId != null &&
          item.serviceId != undefined &&
          item.serviceId === service.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }

      if (sum > this.getAmount(service.afterTaxAmount)) {
        return paidPaymentList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getServiceDueCheck(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.serviceId != null &&
          item.serviceId != undefined &&
          item.serviceId === service.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }

      if (sum < this.getAmount(service.afterTaxAmount)) {
        return paidPaymentList;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getServicePaidListById(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.serviceId != null &&
          item.serviceId != undefined &&
          item.serviceId === service.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getServiceNotPaidListById(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.serviceId === service.id &&
            item.status != null &&
            item.status === "NotPaid") ||
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.serviceId === service.id &&
            item.status != null &&
            item.status === "Paid");
        (item.paymentMode != null && item.paymentMode === "Credit") ||
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.serviceId === service.id &&
            item.status != null &&
            item.status === "Paid");
        item.paymentMode != null && item.paymentMode === "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getServicePaidAmountById(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.serviceId != null &&
          item.serviceId != undefined &&
          item.serviceId === service.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getServicePaidAmountByOrderId(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.orderId != null &&
          item.orderId != undefined &&
          item.orderId === service.orderId &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getServicePaidListByOrderId(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.orderId != null &&
          item.orderId != undefined &&
          item.orderId === service.orderId &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getServiceNotPaidListByOrderId(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.orderId != null &&
            item.orderId != undefined &&
            item.orderId === service.orderId &&
            item.status != null &&
            item.status === "NotPaid") ||
          (item.serviceId != null &&
            item.serviceId != undefined &&
            item.orderId === service.orderId &&
            item.status != null &&
            item.status === "Paid");
        (item.paymentMode != null && item.paymentMode === "Credit") ||
          (item.orderId != null &&
            item.orderId != undefined &&
            item.orderId === service.orderId &&
            item.status != null &&
            item.status === "Paid");
        item.paymentMode != null && item.paymentMode === "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getExpensePaidAmountById(paymentList, expense) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.expenseId === expense.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getExpensePaidListById(paymentList, expense) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.expenseId === expense.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getExpenseNotPaidListById(paymentList, expense) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          (item.expenseId != null &&
            item.expenseId != undefined &&
            item.expenseId === expense.id &&
            item.status != null &&
            item.status === "NotPaid") ||
          (item.expenseId != null &&
            item.expenseId != undefined &&
            item.expenseId === expense.id &&
            item.status != null &&
            item.status === "Paid");
        (item.paymentMode != null && item.paymentMode === "Credit") ||
          (item.expenseId != null &&
            item.expenseId != undefined &&
            item.expenseId === expense.id &&
            item.status != null &&
            item.status === "Paid");
        item.paymentMode != null && item.paymentMode === "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getTotalExpensePaidWOTRefundAmount(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.status != null &&
          item.status === "Paid" &&
          item.paymentMode != null &&
          item.paymentMode != "Credit" &&
          item.paymentMode != "BillToRoom" &&
          item.businessServiceName != null &&
          item.businessServiceName != "Booking Refund";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }

    return sum;
  }

  getServicePaidZeroAmountByOrderId(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.orderId != null &&
          item.orderId != undefined &&
          item.orderId === service.orderId &&
          item.status != null &&
          item.status === "Paid" &&
          item.transactionAmount === 0;

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getServicePaidZeroAmountByServiceId(paymentList, service) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.serviceId != null &&
          item.serviceId != undefined &&
          item.serviceId === service.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.transactionAmount === 0;

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getExpensePaidZeroAmountByExpenseId(paymentList, expense) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.expenseId != null &&
          item.expenseId != undefined &&
          item.expenseId === expense.id &&
          item.status != null &&
          item.status === "Paid" &&
          item.transactionAmount === 0;

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      return paidPaymentList;
    } else {
      return [];
    }
  }

  getTotalCreditPaymentByBookingPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.paymentMode != null &&
          item.paymentMode === "Credit" ||
          item.paymentMode != null &&
          item.paymentMode === "BillToRoom";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getCreditBillToCompanyPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.paymentMode != null &&
          item.paymentMode === "Credit";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getSettleBillToCompanyPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.paymentMode != null &&
          item.paymentMode === "Credit" &&
          item.status != null &&
          item.status === "Paid";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getRoomBillPayment(paymentList) {
    let sum = 0;
    let paidPaymentList = [];
    if (paymentList != null && paymentList.length > 0) {
      paidPaymentList = paymentList.filter((item) => {
        const searchResult =
          item.paymentMode != null &&
          item.paymentMode === "BillToRoom" &&
          item.status != null &&
          item.status === "Paid";

        return searchResult;
      });
    }

    if (
      paidPaymentList != null &&
      paidPaymentList != undefined &&
      paidPaymentList.length > 0
    ) {
      for (let i = 0; i < paidPaymentList.length; i++) {
        sum = sum + paidPaymentList[i].transactionAmount;
      }
    }
    return sum;
  }

  getAllDiscountAmountMultiBooking(row)
  {
    let discount = 0;

    discount = discount + this.dataCheck(row.discountAmount);

    return discount;
  }

  getAllTaxAmountMultiBooking(row)
  {
    let taxAmount = 0;

    taxAmount = taxAmount + this.dataCheck(row.taxAmount);

    if(row.services != null && row.services != undefined && row.services.length >0)
    {
      for (let i = 0; i < row.services.length; i++)
      {
        taxAmount = taxAmount + this.dataCheck(row.services[i].taxAmount);
      }
    }

    return taxAmount;
  }

  dataCheck(data) {
    if (data != null && data != undefined) {
      return data;
    } else {
      return 0;
    }
  }

}
