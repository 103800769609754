import { Employee } from './views/ems/model/employee';
import { TaxDetails } from "app/views/bookone-onboarding/tax-details/model/TaxDetails";
import { BusinessService } from "./views/master-service/model/businessService";
import { Injectable } from "@angular/core";
import { Room } from "./views/bookone/room/room";
import { Property } from "./views/bookone/property/property";
import { Booking } from "../app/views/bookone/booking/booking";
import { Slot } from "../app/views/master-service/model/slot";
import { PayAndExpenseDialogDTO } from "../app/views/bookone/payment/paymentDialogDTO";
import { Router } from "@angular/router";
import { E } from "@angular/cdk/keycodes";
import { Logger } from "./services/logger.service";
import { ListRoute } from "./views/order/orderlist/listroute";
import { ProductListRoute } from "./views/product/productListRoute";
import { BookingListRoute } from "./views/bookone/booking/booking-list/bookingroute";
import { CustomerRoute } from "./views/bookone/manage-customer/customerRoute";
import { LeadsRoute } from "./views/business-lead/model/leadRoute";
import { DashboardRoute } from "./views/bookone/dashboard/dashboardRoute";
import { CatchCashierReport } from './views/report/payment-report/catchCashierReport';

const TOKEN_KEY = "AuthToken";
const USER_ID = "UserId";
const PROPERTY_ID = "PropertyId";
const EMP_ID = "EmpId";
const ONBOARDING_PROPERTY_ID = "OnboardingPId";
const ONBOARDING_USER_ID = "OnboardUserId";
const ORGANIZATION_ID = "OrganizationId";
const ROOM_TYPES = "RoomDetails";
const PROPERTY_DETAILS = "PropertyDetails";
const ROLES = "Roles";

const BOOKING_DETAILS = "BookingDetails";
const ROOM_DETAILS = "P-Room-Detail";
const USER_NAME = "UserName";

const BUSINESS_SERVICE = "businessservice";
const ALLOCATION_SERVICE = "allocationservice";
const ALLOCATION_SERVICE_DATE = "allocationdatelist";

const NOTIFICATION_VALUE = "natificationvalue";
const ORDER_ROUTE = "orderroute";
const PRODUCT_ROUTE = "productroute";
const BOOKING_ROUTE = "bookingroute";
const ROOM_ROUTE = "roomroute";
const CUSTOMER_ROUTE = "customerroute";
const REQUEST_HANDLE = "requestvalue";
const LEAD_ROUTE = "leadroute";
const DASHBOARD_ROUTE = "dashboardroute";

const ORDER_LAYOUT = "orderlayout";
const CASHIER_REPORT = "cashierreport";

@Injectable()
export class TokenStorage {
  rooms: Room[];
  booking: Booking;
  UserId: any;

  constructor(private router: Router) {}

  public setItem(key, value) {
    value = JSON.stringify(value);
    window.sessionStorage.setItem(key, value);
    return true;
  }

  public getItem(key) {
    const value = window.sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  signOut() {
    window.sessionStorage.removeItem(CASHIER_REPORT);
    window.sessionStorage.removeItem(ALLOCATION_SERVICE);
    window.sessionStorage.removeItem(ALLOCATION_SERVICE_DATE);
    window.sessionStorage.removeItem(ONBOARDING_USER_ID);
    window.sessionStorage.removeItem(ONBOARDING_PROPERTY_ID);
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.removeItem(ORGANIZATION_ID);
    window.sessionStorage.removeItem(PROPERTY_ID);
    window.sessionStorage.removeItem(EMP_ID);
    window.sessionStorage.removeItem(ROOM_TYPES);
    window.sessionStorage.removeItem(ROLES);
    window.sessionStorage.removeItem(NOTIFICATION_VALUE);
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    window.sessionStorage.removeItem(ORDER_ROUTE);
    window.sessionStorage.removeItem(PRODUCT_ROUTE);
    window.sessionStorage.removeItem(BOOKING_ROUTE);
    window.sessionStorage.removeItem(ROOM_ROUTE);
    window.sessionStorage.removeItem(CUSTOMER_ROUTE);
    window.sessionStorage.removeItem(REQUEST_HANDLE);
    window.sessionStorage.removeItem(LEAD_ROUTE);
    window.sessionStorage.removeItem(DASHBOARD_ROUTE);
    window.sessionStorage.removeItem(ORDER_LAYOUT);
    window.sessionStorage.clear();
  }

  isRoomNumberAvailableInRoomId(roomId : number , roomNumber : string){

    let rooms = this.getRoomDetail();

    if(rooms != null && rooms != undefined && rooms.length >0)
    {
      let room = rooms.find(data=>data.id === roomId);
      if(room != null && room != undefined)
      {
        if(room.roomDetails != null && room.roomDetails != undefined && room.roomDetails.length >0)
        {
          let roomDetails = room.roomDetails;
          if(roomDetails.some(data=>data.roomNumber === roomNumber) === true)
          {
            return true;
          }
          else
          {
            return false;
          }
        }
        else
        {
          return false;
        }
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }

  }

  public setOrderlayout(data: boolean) {
    window.sessionStorage.removeItem(ORDER_LAYOUT);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(ORDER_LAYOUT, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(ORDER_LAYOUT, null);
    }
  }

  public getOrderLayout(): boolean {
    return JSON.parse(sessionStorage.getItem(ORDER_LAYOUT));
  }

  public saveDashBoardRoteFile(data: DashboardRoute) {
    window.sessionStorage.removeItem(DASHBOARD_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(DASHBOARD_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(DASHBOARD_ROUTE, null);
    }
  }
  public clearDashboardRoute() {
    window.sessionStorage.removeItem(DASHBOARD_ROUTE);
  }

  public getCashierReprotCache(): CatchCashierReport {
    return JSON.parse(sessionStorage.getItem(CASHIER_REPORT));
  }


  public saveCashierReprotCache(data: CatchCashierReport) {
    window.sessionStorage.removeItem(CASHIER_REPORT);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(CASHIER_REPORT, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(CASHIER_REPORT, null);
    }
  }

  public clearCashierReprotCache() {
    window.sessionStorage.removeItem(CASHIER_REPORT);
  }


  public getDashBoardRoteFile(): DashboardRoute {
    return JSON.parse(sessionStorage.getItem(DASHBOARD_ROUTE));
  }

  public checkRequestDialog(): string {
    return JSON.parse(sessionStorage.getItem(REQUEST_HANDLE));
  }

  public clearRequestDialog() {
    window.sessionStorage.removeItem(REQUEST_HANDLE);
  }

  public voidRequestDialog(value: string) {
    window.sessionStorage.removeItem(REQUEST_HANDLE);
    window.sessionStorage.setItem(REQUEST_HANDLE, value);
  }

  public saveCustomerRoteFile(data: CustomerRoute) {
    window.sessionStorage.removeItem(CUSTOMER_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(CUSTOMER_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(CUSTOMER_ROUTE, null);
    }
  }

  public getCustomerRoteFile(): CustomerRoute {
    return JSON.parse(sessionStorage.getItem(CUSTOMER_ROUTE));
  }

  public clearCustomerRoteFile() {
    window.sessionStorage.removeItem(CUSTOMER_ROUTE);
  }

  public saveRoomRoute(value: string) {
    window.sessionStorage.removeItem(ROOM_ROUTE);
    window.sessionStorage.setItem(ROOM_ROUTE, value);
  }

  public getRoomRoute(): string {
    return sessionStorage.getItem(ROOM_ROUTE);
  }

  public saveLeadRoteFile(data: LeadsRoute) {
    window.sessionStorage.removeItem(LEAD_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(LEAD_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(LEAD_ROUTE, null);
    }
  }

  public getLeadRoteFile(): LeadsRoute {
    return JSON.parse(sessionStorage.getItem(LEAD_ROUTE));
  }

  public cleaLeadRoteFile() {
    window.sessionStorage.removeItem(LEAD_ROUTE);
  }


  public saveBookingRoteFile(data: BookingListRoute) {
    window.sessionStorage.removeItem(BOOKING_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(BOOKING_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(BOOKING_ROUTE, null);
    }
  }

  public getBookingRoteFile(): BookingListRoute {
    return JSON.parse(sessionStorage.getItem(BOOKING_ROUTE));
  }

  //

  public saveProductRoteFile(data: ProductListRoute) {
    window.sessionStorage.removeItem(PRODUCT_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(PRODUCT_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(PRODUCT_ROUTE, null);
    }
  }

  public getProductRoteFile(): ProductListRoute {
    return JSON.parse(sessionStorage.getItem(PRODUCT_ROUTE));
  }

  public clearProductRoteFile() {
    window.sessionStorage.removeItem(PRODUCT_ROUTE);
  }

  //
  public saveOrderRoteFile(data: ListRoute) {
    window.sessionStorage.removeItem(ORDER_ROUTE);
    if (data !== null || data !== undefined) {
      window.sessionStorage.setItem(ORDER_ROUTE, JSON.stringify(data));
    } else {
      window.sessionStorage.setItem(ORDER_ROUTE, null);
    }
  }

  public getOrderRoteFile(): ListRoute {
    return JSON.parse(sessionStorage.getItem(ORDER_ROUTE));
  }

  public clearOrderRoteFile() {
    window.sessionStorage.removeItem(ORDER_ROUTE);
  }

  //

  getTaxDetails(businessService: any, property: Property) {
    if (
      businessService.taxDetails != null &&
      businessService.taxDetails != undefined &&
      businessService.taxDetails.length > 0
    ) {
      let taxDetails = businessService.taxDetails[0].taxSlabsList;
      if (
        taxDetails != null &&
        taxDetails != undefined &&
        businessService.taxDetails.length > 0
      ) {
        return businessService.taxDetails;
      }
    } else if (
      property.taxDetails != null &&
      property.taxDetails != undefined &&
      property.taxDetails.length > 0
    ) {
      let taxDetails = property.taxDetails[0].taxSlabsList;
      if (
        taxDetails != null &&
        taxDetails != undefined &&
        property.taxDetails.length > 0
      ) {
        return property.taxDetails;
      }
    } else {
      let taxdetail = [];
      return taxdetail;
    }
  }

  getTaxPercentageByTaxDetail(amount: number, taxdetails: TaxDetails) {
    if (taxdetails != null && taxdetails != undefined) {
      let taxSlabList = taxdetails.taxSlabsList;
      if (
        taxSlabList != null &&
        taxSlabList != undefined &&
        taxSlabList.length > 0
      ) {
        let sizeOfTaxArray = taxSlabList.length;
        for (let i = 0; i < sizeOfTaxArray; i++) {
          let minAmount = taxSlabList[i].minAmount;
          let maxAmount = taxSlabList[i].maxAmount;
          let taxSlab = taxSlabList[i];
          if (amount >= minAmount && amount <= maxAmount) {
            return taxSlab.percentage;
            break;
          }
        }
      } else {
        return taxdetails.percentage;
      }
    }
  }

  getTaxPercentage(amount: number, businessService: BusinessService) {
    if (
      businessService.taxDetails != null &&
      businessService.taxDetails != undefined &&
      businessService.taxDetails.length > 0
    ) {
      let taxDetails = businessService.taxDetails[0].taxSlabsList;
      if (
        taxDetails != null &&
        taxDetails != undefined &&
        businessService.taxDetails.length > 0
      ) {
        let taxSlabList = businessService.taxDetails[0].taxSlabsList;
        if (
          taxSlabList != null &&
          taxSlabList != undefined &&
          taxSlabList.length > 0
        ) {
          let sizeOfTaxArray = taxSlabList.length;
          for (let i = 0; i < sizeOfTaxArray; i++) {
            let minAmount = taxSlabList[i].minAmount;
            let maxAmount = taxSlabList[i].maxAmount;
            let taxSlab = taxSlabList[i];

            if (amount >= minAmount && amount <= maxAmount) {
              return taxSlab.percentage;
              break;
            }
          }
        } else {
          return businessService.taxDetails[0].percentage;
        }
      }
    } else {
      let taxDetails = this.getProperty().taxDetails[0].taxSlabsList;
      if (
        taxDetails != null &&
        taxDetails != undefined &&
        this.getProperty().taxDetails.length > 0
      ) {
        let taxSlabList = this.getProperty().taxDetails[0].taxSlabsList;
        if (
          taxSlabList != null &&
          taxSlabList != undefined &&
          taxSlabList.length > 0
        ) {
          let sizeOfTaxArray = taxSlabList.length;
          for (let i = 0; i < sizeOfTaxArray; i++) {
            let minAmount = taxSlabList[i].minAmount;
            let maxAmount = taxSlabList[i].maxAmount;
            let taxSlab = taxSlabList[i];
            if (amount >= minAmount && amount <= maxAmount) {
              return taxSlab.percentage;
              break;
            }
          }
        } else {
          return this.getProperty().taxDetails[0].percentage;
        }
      }
    }
  }

  home() {
    if (
      this.getProperty() != null &&
      this.getProperty().businessType !== undefined &&
      this.getProperty().businessType.toLocaleLowerCase() !== "accommodation"
    ) {
      if (this.getProperty().plan === "Business Starter") {
        this.router.navigate(["/master-service/business-dashboard/profile"]);
      } else {
        this.router.navigate(["/master-service/home"]);
      }
    } else {
      this.router.navigate(["/master-service/home"]);
    }
  }

  getCounter()
  {
    let counterName = this.getProperty().name +"-"+ this.getProperty().id;
    return  counterName;
  }

  getCounterNumber()
  {
    return String(this.getProperty().id);
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveNotificationValue(value: string) {
    window.sessionStorage.removeItem(NOTIFICATION_VALUE);
    window.sessionStorage.setItem(NOTIFICATION_VALUE, value);
  }

  public getNotificationValue(): string {
    return sessionStorage.getItem(NOTIFICATION_VALUE);
  }

  //

  public saveAllocationService(services: Slot) {
    window.sessionStorage.removeItem(ALLOCATION_SERVICE);
    if (services !== null || services !== undefined) {
      window.sessionStorage.setItem(
        ALLOCATION_SERVICE,
        JSON.stringify(services)
      );
    } else {
      window.sessionStorage.setItem(ALLOCATION_SERVICE, null);
    }
  }

  public getAllocationService(): Slot {
    return JSON.parse(sessionStorage.getItem(ALLOCATION_SERVICE));
  }

  public saveAllocationDate(services: any[]) {
    window.sessionStorage.removeItem(ALLOCATION_SERVICE_DATE);
    if (services !== null || services !== undefined) {
      window.sessionStorage.setItem(
        ALLOCATION_SERVICE_DATE,
        JSON.stringify(services)
      );
    } else {
      window.sessionStorage.setItem(ALLOCATION_SERVICE_DATE, null);
    }
  }

  public getAllocationDate(): any[] {
    return JSON.parse(sessionStorage.getItem(ALLOCATION_SERVICE_DATE));
  }

  public clearAllocationService() {
    window.sessionStorage.removeItem(ALLOCATION_SERVICE);
    window.sessionStorage.removeItem(ALLOCATION_SERVICE_DATE);
  }

  //..

  public saveBusinessService(services: BusinessService[]) {
    window.sessionStorage.removeItem(BUSINESS_SERVICE);
    if (services !== null || services !== undefined) {
      window.sessionStorage.setItem(BUSINESS_SERVICE, JSON.stringify(services));
    } else {
      window.sessionStorage.setItem(BUSINESS_SERVICE, null);
    }
  }

  public getBusinessService(): BusinessService[] {
    return JSON.parse(sessionStorage.getItem(BUSINESS_SERVICE));
  }

  public clearRoomTypes() {
    window.sessionStorage.removeItem(ROOM_TYPES);
  }

  public saveUserName(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(USER_NAME, token);
  }

  public getUserName(): string {
    return sessionStorage.getItem(USER_NAME);
  }

  public saveUserId(userId: number) {
    //Logger.log(`User ID Inside Token Stoarge` + userId);
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.setItem(USER_ID, userId.toString());
  }
  public getUserId(): string {
    return sessionStorage.getItem(USER_ID);
  }
  //...
  public saveOnBoardingUserId(userId: number) {
    // Logger.log(`User ID Inside Token Stoarge onboarding UserID` + userId);
    window.sessionStorage.removeItem(ONBOARDING_USER_ID);
    window.sessionStorage.setItem(ONBOARDING_USER_ID, userId.toString());
  }

  clearPropertyId() {
    window.sessionStorage.removeItem(PROPERTY_ID);
  }

  clearOnBoardingUserId() {
    window.sessionStorage.removeItem(ONBOARDING_USER_ID);
  }
  public getOnBoardingUserId(): string {
    // Logger.log(`Onboarding sesssion id inside session storage ${sessionStorage.getItem(ONBOARDING_USER_ID)}`);
    return sessionStorage.getItem(ONBOARDING_USER_ID);
  }
  //..
  public saveOnBoardingPropertyId(propertyId: string) {
    window.sessionStorage.removeItem(ONBOARDING_PROPERTY_ID);
    window.sessionStorage.setItem(
      ONBOARDING_PROPERTY_ID,
      propertyId.toString()
    );
  }
  public getOnBoardingPropertyId(): string {
    return sessionStorage.getItem(ONBOARDING_PROPERTY_ID);
  }
  public saveOrganizationId(organizationId: number) {
    window.sessionStorage.removeItem(ORGANIZATION_ID);
    window.sessionStorage.setItem(ORGANIZATION_ID, organizationId.toString());
  }
  public getOrganizationId(): string {
    return sessionStorage.getItem(ORGANIZATION_ID);
  }
  public getPropertyId(): string {
    return sessionStorage.getItem(PROPERTY_ID);
  }
  public getEmpId(): string {
    return sessionStorage.getItem(EMP_ID);
  }
  public getRoomTypes(): Room[] {
    return JSON.parse(sessionStorage.getItem(ROOM_TYPES));
  }
  public getProperty(): Property {
    return JSON.parse(sessionStorage.getItem(PROPERTY_DETAILS));
  }
  public getEmp(): Property {
    return JSON.parse(sessionStorage.getItem(PROPERTY_DETAILS));
  }
  public savePropertyId(propertyId: number) {
    // Logger.log(`User ID Inside Token Stoarge` + propertyId);
    window.sessionStorage.removeItem(PROPERTY_ID);
    if (propertyId != null) {
      window.sessionStorage.setItem(PROPERTY_ID, propertyId.toString());
    } else {
      window.sessionStorage.setItem(PROPERTY_ID, null);
    }
  }
  public saveRoomTypes(roomTypes: Room[]) {
    // Logger.log(roomTypes);
    window.sessionStorage.removeItem(ROOM_TYPES);
    if (roomTypes !== null || roomTypes !== undefined) {
      window.sessionStorage.setItem(ROOM_TYPES, JSON.stringify(roomTypes));
    } else {
      window.sessionStorage.setItem(ROOM_TYPES, null);
    }
  }
  public saveProperty(property: Property) {
    window.sessionStorage.removeItem(PROPERTY_DETAILS);
    if (property != null) {
      window.sessionStorage.setItem(PROPERTY_DETAILS, JSON.stringify(property));
    } else {
      window.sessionStorage.setItem(PROPERTY_DETAILS, null);
    }
  }
  public saveRole(roles: string[]) {
    window.sessionStorage.removeItem(ROLES);
    window.sessionStorage.setItem(ROLES, JSON.stringify(roles));
  }

  public getRole(): string {
    return sessionStorage.getItem(ROLES);
  }

  ///.............. booking object

  public getBookingId(): string {
    this.booking = new Booking();
    this.booking = JSON.parse(sessionStorage.getItem(BOOKING_DETAILS));
    // Logger.log('token this.booking: '+this.booking);
    if (this.booking === null) {
      return null;
    } else {
      return String(this.booking.id);
    }
  }

  public saveBooking(booking: Booking) {
    window.sessionStorage.removeItem(BOOKING_DETAILS);
    if (booking != null) {
      window.sessionStorage.setItem(BOOKING_DETAILS, JSON.stringify(booking));
    } else {
      window.sessionStorage.setItem(BOOKING_DETAILS, null);
    }
  }

  public getBooking(): Booking {
    return JSON.parse(sessionStorage.getItem(BOOKING_DETAILS));
  }

  public clearBooking() {
    window.sessionStorage.removeItem(BOOKING_DETAILS);
  }
  //....
  public saveRoomDetail(room: Room) {
    window.sessionStorage.removeItem(ROOM_DETAILS);
    if (room != null) {
      window.sessionStorage.setItem(ROOM_DETAILS, JSON.stringify(room));
    } else {
      window.sessionStorage.setItem(ROOM_DETAILS, null);
    }
  }

  public getRoomDetail(): Room[] {
    return JSON.parse(sessionStorage.getItem(ROOM_DETAILS));
  }

  public clearRoomDetail() {
    window.sessionStorage.removeItem(ROOM_DETAILS);
  }

  roomSequenceByRanking(ascending) {
    return function (a, b) {
      // equal items sort equally
      if (a.ranking === b.ranking) {
          return 0;
      }

      // nulls sort after anything else
      if (a.ranking === null) {
          return 1;
      }
      if (b.ranking === null) {
          return -1;
      }

      if (a.ranking === 0) {
        return 1;
      }
      if (b.ranking === 0) {
          return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
          return a.ranking < b.ranking ? -1 : 1;
      }

      // if descending, highest sorts first
      return a.ranking < b.ranking ? 1 : -1;
    };
  }

}
